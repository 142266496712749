import React from "react";
import { IconButton, AppBar, Toolbar, Box, Divider, Menu, MenuItem, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
// import axios from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GlobalContext from "context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import { scroller, animateScroll } from "react-scroll";

import KakaoTalkIcon from "resources/Images/Home/KakaoTalkIcon.png";
import DiscordIcon from "resources/Images/Home/DiscordIcon.png";
import TwitterIcon from "resources/Images/Home/TwitterIcon.png";
// import YoutubeIcon from "resources/Images/Home/YoutubeIcon.png";
// import InstagramIcon from "resources/Images/Home/InstagramIcon.png";
// import MarketIcon from "resources/Images/Home/MarketIcon.png";
import KrIcon from "resources/Images/Home/KrIcon.png";
import HeaderLogo from "resources/Images/Home/HeaderLogo.png";
import HeaderMenu1 from "resources/Images/Home/HeaderMenu1.png";
import HeaderMenu2 from "resources/Images/Home/HeaderMenu2.png";
import HeaderMenu3 from "resources/Images/Home/HeaderMenu3.png";
import HeaderMenu4 from "resources/Images/Home/HeaderMenu4.png";

const Header = () => {
  // const [anchorEl, setAnchorEl] = React.useState({ x: null, y: null });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setNetwork, walletVerification, walletVerificationMetamask } = React.useContext(GlobalContext);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const mdView = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // const menuOpen = (e) => {
  //   if (history.location.pathname === "/") {
  //     return setAnchorEl({
  //       x: e.clientX - 68,
  //       y: e.clientY + 20,
  //     });
  //   } else {
  //     return history.push("/");
  //   }
  // };

  const open = Boolean(anchorEl);

  const menuOpen = (e) => {
    if (history.location.pathname === "/") {
      return setAnchorEl(e.currentTarget);
    } else {
      return history.push("/");
    }
  };

  const menuClose = () => {
    setAnchorEl(null);
  };

  // const menuClose = () => {
  //   setAnchorEl({ x: null, y: null });
  // };

  React.useEffect(() => {
    if (open) {
      menuClose();
    }
  }, [mdView]);

  const menuFunction = (e, action) => {
    switch (action) {
      case "project":
        return history.push("/");

        return enqueueSnackbar(t("리뉴얼 작업중입니다."), { variant: "info" });

        if (history.location.pathname === "/") {
          return menuOpen(e);
        } else {
          return history.push("/");
        }
      case "governance":
        return enqueueSnackbar(t("리뉴얼 작업중입니다."), { variant: "info" });

      case "buy":
        // return history.push("/");

        return enqueueSnackbar(t("리뉴얼 작업중입니다."), { variant: "info" });
      case "mining":
        return enqueueSnackbar(t("리뉴얼 작업중입니다."), { variant: "info" });

      // return walletVerification(() => history.push("/mining"));
      case "staking":
        // return enqueueSnackbar(t("곧 준비됩니다."), { variant: "info" });

        // return walletVerificationMetamask(() => history.push("/staking"));
        return walletVerificationMetamask(() => history.push("/staking"));

      case "mint":
        // return enqueueSnackbar(t("곧 준비됩니다."), { variant: "info" });

        // return walletVerification(() => history.push("/mint-v"));

        // return enqueueSnackbar(t("곧 준비됩니다."), { variant: "info" });
        return;
      case "connectWallet":
        // return enqueueSnackbar(t("곧 준비됩니다."), { variant: "info" });

        return walletVerification(() => history.push("wallet"));
      default:
        return;
    }
  };

  const iconFunction = (action) => {
    switch (action) {
      case "twitter":
        return window.open("https://twitter.com/RTKV_nft");
      case "discord":
        return window.open("https://discord.gg/tHfY4cbUSM");
      case "kakaoTalk":
        return window.open("https://open.kakao.com/o/gQbeuN2d");
      default:
        return "";
    }
  };

  // const logoutOnClick = async () => {
  //   setIsLogin(false);
  //   sessionStorage.removeItem("token");
  //   return enqueueSnackbar(t("로그아웃 되었습니다."), { variant: "success" });
  // };

  const headerMenuOnClick = (action) => {
    scroller.scrollTo(action, {
      duration: 500,
      smooth: true,
      offset: -70,
    });
  };

  return (
    <React.Fragment>
      <Toolbar />
      <AppBar enableColorOnDark position="fixed" className={classes.appBar}>
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Menu
            id="basic-menu"
            open={open}
            // open={anchorEl.y !== null}
            onClose={menuClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            // anchorReference="anchorPosition"
            // anchorPosition={anchorEl.x && anchorEl.y ? { top: anchorEl.y, left: anchorEl.x } : undefined}
            disableScrollLock={true}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              style: {
                display: open ? "inherit" : "none",
              },
            }}
            PaperProps={{
              style: {
                backgroundColor: theme.palette.black,
                boxShadow: `1px 1px 15px 0 ${theme.palette.boxShadow}`,
              },
              // onMouseLeave: menuClose,
            }}
          >
            {[t("Taekwon V"), t("Technologies"), t("Roadmap"), t("Team"), t("Partner")].map((item, index) => {
              return (
                <MenuItem
                  key={item + index}
                  className={classes.menuItem}
                  onClick={headerMenuOnClick.bind(null, `${item}`)}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Menu>

          <Box className={classes.logoBox} onClick={() => history.push("/")}>
            {/* <CardMedia className={classes.logo} component="img" image={HeaderLogo} /> */}
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            {!mdView && (
              <React.Fragment>
                {[
                  { id: 1, menu: "Project", action: "project" },
                  { id: 2, menu: "Governance", action: "governance" },
                  // { id: 3, menu: "Mining", action: "mining" },
                  { id: 4, menu: "Staking", action: "staking" },
                  // { id: 5, menu: "Mint", action: "mint" },
                  { id: 6, menu: "Connect Wallet", action: "connectWallet", last: true },
                ].map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <IconButton style={{ padding: 10 }} onClick={(e) => menuFunction(e, item.action)}>
                        <span style={{ fontSize: 18, fontWeight: "bold" }}>{item.menu}</span>
                        {/* <CardMedia className={classes.menu1} component="img" image={item.menu} /> */}
                      </IconButton>
                      {!item.last && (
                        <Divider orientation="vertical" variant="middle" className={classes.appBarDivider} />
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            {mdView && (
              <React.Fragment>
                <IconButton onClick={(e) => menuOpen(e)} className={classes.iconButton}>
                  <MenuIcon className={classes.icon} />
                </IconButton>
              </React.Fragment>
            )}
            {/* 
            {isLogin ? (
              <IconButton onClick={logoutOnClick} className={classes.iconButton}>
                <LogoutIcon className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton onClick={loginOnClick} className={classes.iconButton}>
                <VpnKeyIcon className={classes.icon} />
              </IconButton>
            )} */}

            <React.Fragment>
              {[
                { id: 1, icon: TwitterIcon, action: "twitter" },
                { id: 2, icon: DiscordIcon, action: "discord" },
                // { id: 3, icon: KakaoTalkIcon, action: "kakaoTalk" },
                // { id: 4, icon: YoutubeIcon, action: "youtube" },
                // { id: 5, icon: InstagramIcon, action: "instagram" },
              ].map((item) => {
                return (
                  <IconButton
                    key={item.id}
                    className={classes.iconButton}
                    onClick={() => {
                      iconFunction(item.action);
                    }}
                  >
                    <CardMedia component="img" image={item.icon} className={classes.imageIcon} />
                  </IconButton>
                );
              })}
            </React.Fragment>

            <Divider orientation="vertical" variant="middle" className={classes.iconBoxDivider} />

            <IconButton className={classes.krIconButton}>
              <span style={{ fontSize: 17 }}>KR</span>
            </IconButton>

            {/* <IconButton className={classes.krIconButton}>
              <CardMedia className={classes.imageIcon2} component="img" image={MarketIcon} />
            </IconButton> */}
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.white,
    paddingTop: 10,
    paddingBottom: 5,
    height: "80px",
    [theme.breakpoints.down("md")]: {
      height: "70px",
    },
  },
  logoBox: {
    width: 130,
    [theme.breakpoints.down("md")]: {
      width: 90,
    },
    cursor: "pointer",
  },
  logo: {
    objectFit: "contain",
  },
  menu1: {
    height: 22,
    objectFit: "contain",

    // "&:hover": { backgroundColor: "red" },
  },

  appBarDivider: {
    height: 14,
    backgroundColor: theme.palette.white,
    marginLeft: 15.5,
    marginRight: 15.5,
  },
  icon: {
    fontSize: 25,
    color: theme.palette.red,
  },
  imageIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  imageIcon2: {
    width: 25,
    height: 25,
    objectFit: "contain",
  },
  iconButton: {
    width: 28,
    height: 28,
    padding: 13,
    marginRight: 5,
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  krIconButton: {
    width: 28,
    height: 28,
    padding: 13,
    marginRight: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.red,
    },
  },
  iconBoxDivider: {
    height: 15,
    backgroundColor: theme.palette.white,
    marginRight: 13.5,
    marginLeft: 5,
  },
  menuItem: {
    color: theme.palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.red,
    },
  },
}));

export default Header;
