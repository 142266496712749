import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box, Typography, CardMedia } from "@mui/material";
import FirstLayerLogo from "resources/Images/HomeRenewal.png";

const HomeFirstLayer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.box}>
      <CardMedia className={classes.homeWallPaperImage} component="img" image={FirstLayerLogo} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "black",
    marginBottom: 100,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 60,
    },
  },
  homeWallPaperImage: {
    width: "100%",
    height: "100vh",
    objectFit: "contain",
  },
  homeWallPaperTitleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 100,
    [theme.breakpoints.down("md")]: {
      bottom: 50,
    },
  },
  homeWallPaperLogo: {
    width: 650,
    [theme.breakpoints.down("md")]: {
      width: 300,
    },
  },
  homeWallPaperTextBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    paddingLeft: 20,
    paddingRight: 20,
    left: 0,
    right: 0,
    bottom: 0,
    top: 120,
    [theme.breakpoints.down("md")]: {},
  },

  homeWallPaperText: {
    height: 87,
    color: theme.palette.white,
    // fontFamily: "Poppins",
    fontStyle: "normal",
    // fontWeight: "400",
    textAlign: "center",
    textShadow: `3px 3px 8px ${theme.palette.textShadow}`,
    maxWidth: 902,
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      maxWidth: 400,
      fontSize: 12,
    },
  },
}));

export default HomeFirstLayer;
