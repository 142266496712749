import React from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, CardMedia, Modal, Typography, useMediaQuery, Button, Grid } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import CloseIcon from "resources/Images/Staking/btn-popup-close.webp";

const StakingModal = ({
  setFocusedGroup,
  harvestStaking,
  registerStaking,
  open,
  setOpen,
  modalType,
  setModalType,
  tab,
  stakingMoreAsset,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const lgView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleClose = () => {
    setFocusedGroup(null);
    setOpen(!open);
    setModalType(null);
  };
  return (
    <React.Fragment>
      {/* myNftAsset 에서 고른거 스테이킹  */}
      {modalType === "staking" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 6 }}>
              <Typography
                sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, width: 140, textAlign: "center" }}
              >
                {t("V토큰 스테이킹을 진행하시겠습니까?")}
              </Typography>
            </Box>
            <Box display="flex" sx={{ borderTop: `1px solid ${theme.palette.borderGrey}` }}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ borderRight: `1px solid ${theme.palette.borderGrey}`, py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingModalText }}>
                  {t("취소")}
                </Typography>
              </Button>
              <Button
                fullWidth
                onClick={async () => {
                  await registerStaking();
                  handleClose();
                }}
                sx={{ py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingAmountColor }}>
                  {t("진행하기")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* stakingAsset 에서 x버튼으로 언스테이킹 */}
      {modalType === "unStaking" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 6 }}>
              <Typography
                sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, width: 140, textAlign: "center" }}
              >
                {t("V토큰 스테이킹을 해제하시겠습니까?")}
              </Typography>
            </Box>
            <Box display="flex" sx={{ borderTop: `1px solid ${theme.palette.borderGrey}` }}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ borderRight: `1px solid ${theme.palette.borderGrey}`, py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingModalText }}>
                  {t("취소")}
                </Typography>
              </Button>
              <Button
                fullWidth
                onClick={harvestStaking.bind(null, { action: "single", terminate: true })}
                sx={{ py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingAmountColor }}>
                  {t("해제하기")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* stakingAsset 에서 Get Tokens 버튼으로 각각 눌러서 보상 수령 */}
      {modalType === "rewardToken" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 7 }}>
              <Typography
                sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, width: 200, textAlign: "center" }}
              >
                {t("V토큰을 수령하시겠습니까?")}
              </Typography>
            </Box>
            <Box display="flex" sx={{ borderTop: `1px solid ${theme.palette.borderGrey}` }}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ borderRight: `1px solid ${theme.palette.borderGrey}`, py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingModalText }}>
                  {t("취소")}
                </Typography>
              </Button>
              <Button
                fullWidth
                onClick={harvestStaking.bind(null, { action: "single" })}
                sx={{ py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingAmountColor }}>
                  {t("수령하기")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* stakingAsset 우측 하단에 현재 풀(tab) 내에 스테이킹 중인 보상 수령 */}
      {modalType === "rewardPool" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 7 }}>
              <Typography
                sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, textAlign: "center", mr: 0.5 }}
              >
                {tab.toUpperCase()}
              </Typography>
              <Typography sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, textAlign: "center" }}>
                {t(" 스테이킹 상품의 토큰을 모두 수령하시겠습니까?")}
              </Typography>
            </Box>
            <Box display="flex" sx={{ borderTop: `1px solid ${theme.palette.borderGrey}` }}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ borderRight: `1px solid ${theme.palette.borderGrey}`, py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingModalText }}>
                  {t("취소")}
                </Typography>
              </Button>
              <Button
                fullWidth
                onClick={harvestStaking.bind(null, { action: "type" })}
                sx={{ py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingAmountColor }}>
                  {t("수령하기")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* Staking 문구 헤더 우측에 Get all Tokens 버튼으로 모든 풀 스테이킹 중인 모든 보상 수령 */}
      {modalType === "rewardTotal" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 7 }}>
              <Typography
                sx={{ fontSize: 17, fontWeight: "500", color: theme.palette.white, width: 280, textAlign: "center" }}
              >
                {t("전체 V토큰을 모두 수령하시겠습니까?")}
                {t("(개별 스테이킹 신청이 모두 종료되어 보상 수령시 스테이킹이 자동 해제됩니다.)")}
              </Typography>
            </Box>
            <Box display="flex" sx={{ borderTop: `1px solid ${theme.palette.borderGrey}` }}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ borderRight: `1px solid ${theme.palette.borderGrey}`, py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingModalText }}>
                  {t("취소")}
                </Typography>
              </Button>
              <Button
                fullWidth
                onClick={harvestStaking.bind(null, { action: "all" })}
                sx={{ py: 3, textTransform: "none" }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: theme.palette.stakingAmountColor }}>
                  {t("수령하기")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* stakingAsset 마우스 오버시에 "펼쳐보기" 모달 */}
      {modalType === "more" && (
        <Modal open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <Box className={classes.modalContainer2}>
            <Box display="flex" justifyContent="flex-end" p={4} pb={0}>
              <IconButton
                onClick={handleClose}
                sx={{
                  p: 0,
                  "&:hover": {
                    WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                    boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                  },
                }}
              >
                <CardMedia component="img" image={CloseIcon} sx={{ width: 34, height: 34 }} />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center">
              <Typography sx={{ fontSize: 24, fontWeight: "700", color: theme.palette.white }}>
                {t("Staking NFT")}
              </Typography>
            </Box>

            <Grid container pb={5} mt={4} px={lgView ? 2 : 6}>
              {stakingMoreAsset &&
                stakingMoreAsset.map((item, index) => {
                  return (
                    <Grid key={"more" + index} item lg={2.4} md={3} sm={4} xs={6} p={theme.spacing(1)}>
                      <Box className={classes.moreBox}>
                        <CardMedia
                          component="video"
                          image={item.image}
                          autoPlay
                          sx={{ width: "100%", height: 170, objectFit: "contain", borderRadius: "16px" }}
                        />
                        <Box
                          className={classes.nameTag}
                          sx={{ opacity: 0.8, backgroundColor: theme.palette.black }}
                        ></Box>
                        <Box className={classes.nameTag}>
                          <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white }}>
                            {item.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Modal>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    modalContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: theme.palette.stakingModal,
      outline: "none",
      borderRadius: "20px",
    },
    modalContainer2: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 1000,
      height: 550,
      overflow: "auto",
      backgroundColor: theme.palette.stakingModal,
      outline: "none",
      borderRadius: "20px",
      [theme.breakpoints.down("lg")]: {
        width: 800,
      },
      [theme.breakpoints.down("md")]: {
        width: 600,
      },
      [theme.breakpoints.down("sm")]: {
        width: 470,
      },
    },
    moreBox: {
      position: "relative",
      width: "100%",
      height: 170,
      backgroundColor: theme.palette.stakingNftBox,
      borderRadius: "16px",
      cursor: "pointer",
      "&:hover": {
        WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    nameTag: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: 40,
      display: "flex",
      justifyContent: "center",
      borderRadius: "14px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingTop: 10,
      paddingBottom: 10,
    },
  };
});

export default StakingModal;
