import React from "react";
import GlobalContext from "context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";
import { useSnackbar } from "notistack";
import useDialog from "hooks/useDialog";
import CustomDialog from "components/CustomDialog";
import { useTranslation } from "react-i18next";
import Web3 from "web3";

const App = () => {
  const [network, setNetwork] = React.useState();
  const [isLogin, setIsLogin] = React.useState(sessionStorage.getItem("token") ? true : false);
  const [initialize, setInitialize] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("ko");
  const [adminTables, setAdminTables] = React.useState(null);
  const [setDialogMessage, dialogProps] = useDialog();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const walletVerification = async (cb = () => null) => {
    if (window.klaytn) {
      // try {
      // const caver = new Caver(window.klaytn);

      await window.klaytn.enable();
      const chainId = await window.klaytn.networkVersion;
      setNetwork(chainId);
      window.klaytn.on("networkChanged", (chainId) => {
        setNetwork(chainId);
        if (chainId !== Number(process.env.REACT_APP_MAINCHAIN)) {
          return enqueueSnackbar(t("네트워크 설정을 확인해주세요."), { variant: "error" });
        }
      });

      const publicAddress = window.klaytn.selectedAddress;

      if (!publicAddress) {
        return enqueueSnackbar(t("Kaikas 잠김 상태를 해제해주세요."), { variant: "error" });
      } else {
        cb();
      }
    } else {
      return enqueueSnackbar(t("Kaikas 가 설치되지 않았습니다."), { variant: "error" });
    }
  };

  const walletVerificationMetamask = async (cb = () => null) => {
    if (window.ethereum) {
      //   window.web3 = new Web3(window.ethereum);
      //   await window.ethereum.enable();
      //   const chainId = await window.ethereum.request({ method: "eth_chainId" });
      //   setNetwork(chainId);
      //   window.ethereum.on("chainChanged", (chainId) => {
      //     setNetwork(chainId);
      //   });
      // } else {
      //   return enqueueSnackbar(t("Metamask 가 설치되지 않았습니다."), { variant: "error" });
      // }
      // const publicAddress = window.ethereum.selectedAddress;
      // if (!publicAddress) {
      //   return enqueueSnackbar(t("Metamask 잠김 상태를 해제해주세요."), { variant: "error" });
      // } else {
      //   cb();
    }
  };

  React.useEffect(() => {});

  const contextValue = {
    user,
    setUser,
    walletVerification,
    walletVerificationMetamask,
    network,
    setNetwork,
    isLogin,
    setIsLogin,
    drawerIsOpen,
    setDrawerIsOpen,
    initialize,
    setInitialize,
    adminTables,
    setAdminTables,
    language,
    setLanguage,
    setDialogMessage,
    dialogProps,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      <CustomDialog setDialogMessage={setDialogMessage} {...dialogProps}>
        {dialogProps.message}
      </CustomDialog>
      <Router>
        <Routes />
      </Router>
    </GlobalContext.Provider>
  );
};

export default App;
