import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, CardMedia } from "@mui/material";
import FooterLogo from "resources/Images/Home/FooterLogo2.png";

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box display="flex" flexDirection="column">
        <CardMedia className={classes.logo} component="img" image={FooterLogo} />
      </Box>
      <Box>
        <Typography className={classes.copyRight}>
          COPYRIGHT ⓒ 2022 by NFT MANIA & INFINITY MARKET. ALL RIGHT RESERVED
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    backgroundColor: theme.palette.black2,
    height: 150,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  logo: {
    height: 18,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      height: 25,
      marginBottom: 10,
    },
  },

  copyRight: {
    width: "100%",
    textAlign: "center",
    color: theme.palette.white,
    fontSize: 14,
    fontFamily: "Poppins",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
}));

export default Footer;
