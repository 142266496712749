import React, { forwardRef } from "react";

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Header from "components/Header";
import Drawer from "components/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Page = forwardRef(({ children, title = "", header = false, ...rest }, ref) => {
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const toolbarHeight = smView ? 56 : 64;

  return (
    <div ref={ref} {...rest} style={{ width: "100vw", height: `calc(100vh - ${toolbarHeight}px)` }}>
      <Helmet>
        <title>{process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      {header && <Header />}
      <Drawer />
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
