import React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { useTranslation } from "react-i18next";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const TabSection = ({ tab, setTab }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mdView = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const Tab = styled(TabUnstyled)`
    color: ${theme.palette.stakingBorder};
    cursor: pointer;
    font-size: ${mdView ? "13px" : "18px"};
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 15px 16px;
    margin: 6px 6px;
    border: 2px solid ${theme.palette.stakingBorder};
    border-radius: 25px;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: ${theme.palette.defaultColor};
    }

    &.${tabUnstyledClasses.selected} {
      background-color: ${theme.palette.stakingAmountColor};
      color: ${theme.palette.stakingContentsBox};
      border: none;
    }
  `;

  const TabsList = styled(TabsListUnstyled)`
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  `;

  return (
    <TabsUnstyled
      value={tab}
      onChange={(e, newValue) => {
        setTab(newValue);
      }}
    >
      <TabsList>
        <Tab value={"v1"}>{t("V1")}</Tab>
        <Tab value={"v3"}>{t("V3")}</Tab>
        <Tab value={"v5"}>{t("V5")}</Tab>
        <Tab value={"v5-a"}>{t("V5-A")}</Tab>
        <Tab value={"v10"}>{t("V10")}</Tab>
      </TabsList>
    </TabsUnstyled>
  );
};

export default TabSection;
