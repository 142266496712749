import React from "react";
import Caver from "caver-js";
import { makeStyles, withStyles } from "@mui/styles";
import { Typography, Box, Divider, Grid, CardMedia, Button, Slider, CircularProgress } from "@mui/material";
import Page from "components/Page";
import Loader from "components/Loader";
import HomeHeader from "components/Home/HomeHeader";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import useMediaQuery from "@mui/material/useMediaQuery";
import ABI from "resources/abi/v_abi";
import { runContract } from "utils";

import BackgroundImage from "resources/Images/BackgroundMintPage.jpeg";
import { useSnackbar } from "notistack";
import GlobalContext from "context/GlobalContext";
import moment from "moment";
import axios from "axios";

const Mint = ({ history }) => {
  const [callResult, setCallResult] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [vBalance, setVBalance] = React.useState(null);
  const [tokenIds, setTokenIds] = React.useState(null);
  const [myMinings, setMyMinings] = React.useState(null);
  const { walletVerification, network } = React.useContext(GlobalContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const mdView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const buyOnClick = async () => {
    if (Number(network) !== Number(process.env.REACT_APP_MAINCHAIN)) {
      return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
    }
    const caver = new Caver(window.klaytn);

    if (window.klaytn) {
      const publicAddress = window.klaytn.selectedAddress;
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/authentication/generate`, {
        publicAddress,
        authType: "kaikas",
      });
      if (data.status) {
        const nonce = data.nonce;

        const signature = await caver.klay.sign(
          caver.utils.fromUtf8(`${process.env.REACT_APP_SIGNATURE_SALT}${nonce}`),
          publicAddress
        );

        const { data: register } = await axios.post(`${process.env.REACT_APP_HOST}/mining/register`, {
          publicAddress,
          signature,
          tokenIds,
          authType: "kaikas",
        });
        if (register.status) {
          enqueueSnackbar(t("마이닝 신청이 완료되었습니다."), { variant: "success" });
          return initialize();
        } else {
          if (register.code === "duplicated_token_id") {
            return enqueueSnackbar(t("이미 마이닝중인 토큰 ID 입니다."), { variant: "error" });
          }
          return enqueueSnackbar(t("올바른 인증이 아닙니다."), { variant: "error" });
        }
      } else {
        enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도해주세요."), { variant: "error" });
      }
    } else {
      enqueueSnackbar(t("메타마스크가 설치되지 않았습니다."), { variant: "error" });
    }
  };
  /**
   * 1. 리밋에 맞게 수량 수정(딱 걸치는애들)
   * 2. 리밋 전부 차면 amount값도 그에따라 바뀌게(안올라가있게비활성화ㄴ)
   *
   */

  const contractCall = async ({ type = "call", method, input = [], value = 0 }) => {
    const caver = new Caver(window.klaytn);
    const mapInput = input.map((item) => {
      return item.value;
    });
    const run = await runContract({
      abi: ABI,
      caver,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
      type,
      from: window.klaytn.selectedAddress,
      method,
      input: mapInput,
      value,
    });
    if (run.result === "error") {
      return enqueueSnackbar(t("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요."), { variant: "error" });
    }

    if (type === "call") {
      const json = { ...callResult };
      json[method] = run.result;
      setCallResult(json);
      return run.result;
    }

    const transactionParameters = {
      from: window.klaytn.selectedAddress, // must match user's active address.
      data: run.result.deploy,
      value: caver.utils.toHex(value),
    };
    transactionParameters["to"] = process.env.REACT_APP_CONTRACT_ADDRESS;
    try {
      window.klaytn.sendAsync(
        {
          method: "klay_sendTransaction",
          params: [transactionParameters],
        },
        (err, result) => {
          initialize();
        }
      );
    } catch (e) {
      enqueueSnackbar(t("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요."), { variant: "error" });
    }
  };

  const initialize = async () => {
    if (window.klaytn) {
      if (window.klaytn.networkVersion !== Number(process.env.REACT_APP_MAINCHAIN)) {
        return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
      }
      const balanceOf = await contractCall({ method: "balanceOf", input: [{ value: window.klaytn.selectedAddress }] });

      const ids = [];

      for (let i = 0; i < balanceOf; i++) {
        const tokenOfOwnerByIndex = await contractCall({
          method: "tokenOfOwnerByIndex",
          input: [{ value: window.klaytn.selectedAddress }, { value: i }],
        });
        ids.push(tokenOfOwnerByIndex);
      }
      setTokenIds(ids);
      setVBalance(balanceOf);

      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/mining/status`, {
        publicAddress: window.klaytn.selectedAddress,
        authType: "kaikas",
      });

      if (data.status) {
        setMyMinings(data.mining);
      }
    }
  };

  useEffectOnce(() => {
    (async () => {
      const caver = new Caver(window.klaytn);
      await walletVerification();
      initialize();
      window.klaytn.on("accountsChanged", function ([accounts]) {
        initialize();
      });
    })();
  });

  return (
    <Page title={"Mint"}>
      <React.Fragment>
        <HomeHeader />
        <Box className={classes.container}>
          <CardMedia className={classes.backgroundImage} component="img" image={BackgroundImage} />
          {vBalance && myMinings && tokenIds ? (
            <Box className={classes.box}>
              <Box sx={{ maxWidth: "80%" }}>
                <Grid container className={classes.gridContainer}>
                  <Box className={classes.gridBox}>
                    <Box className={classes.box3}>
                      <Box
                        sx={{
                          width: 500,
                          px: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: 600,

                            px: 3,
                            py: 1,
                            mb: 3,
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 1,
                              pb: 1,
                              borderBottom: "1px solid lightgrey",
                            }}
                          >
                            <span>{t("보유 NFT 수량")}</span>
                            <span>{vBalance}</span>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 1,
                            }}
                          >
                            <span>{t("마이닝중인 NFT")}</span>
                            <span>{t("시작일")}</span>
                          </Box>
                          {myMinings.length === 0
                            ? "-"
                            : myMinings.map((mine, index) => {
                                return (
                                  <Box
                                    key={index + "mining"}
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      mb: 1,
                                    }}
                                  >
                                    <span>TokenID [{mine.token_id}]</span>
                                    <span>{moment(mine.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
                                  </Box>
                                );
                              })}
                        </Box>
                        <Button
                          disabled={vBalance === "0"}
                          size="large"
                          sx={{ width: 200 }}
                          variant="contained"
                          onClick={buyOnClick}
                        >
                          {t("마이닝 신청")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Loader></Loader>
          )}
        </Box>
      </React.Fragment>
    </Page>
  );
};

const CustomSlider = withStyles((theme) => ({
  track: {
    // opacity: 0,
    height: 35,
    backgroundColor: theme.palette.lightPupple,
    borderRadius: 15,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rail: {
    backgroundColor: theme.palette.slider,
    borderRadius: 15,
    height: 35,
    // width: "100%",
    // opacity: 0.9,
    // border: `1px solid ${theme.palette.white}`,
  },
  thumb: {
    // display: "none",
    backgroundColor: theme.palette.white,
    height: 34,
    width: 34,
    // [theme.breakpoints.down("sm")]: {
    //   height: 16,
    //   width: 16,
    // },
  },
  mark: {
    display: "none",
    // height: 35,
  },
  markLabel: {
    marginTop: 10,
  },
  valueLabel: {
    backgroundColor: theme.palette.lightPupple,
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.black,
    position: "relative",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
    // [theme.breakpoints.down("lg")]: {
    //   display: "none",
    //   width: "none",
    //   height: "none",
    // },
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  flexBox: {
    zIndex: 5,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 30,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  },
  logoBox: {
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 20,
      marginLeft: 0,
    },
  },
  logo1: {
    width: 250,
    objectFit: "contain",
    marginBottom: 20,
    [theme.breakpoints.down("lg")]: {
      width: 250,
      marginBottom: 30,
    },
  },
  box2: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  sliderBox: {
    marginLeft: 10,
    marginRight: 10,
    width: 320,
    [theme.breakpoints.down("lg")]: {
      marginLeft: 10,
      marginRight: 20,
      width: 230,
    },
  },
  logoBox1: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {},
  },
  logoTitleBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "center",
    },
  },

  amountNumber: {
    marginTop: -4,
    color: theme.palette.lightPupple,
    fontSize: 26,
    fontWeight: "500",
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
      marginTop: 10,
    },
  },
  logo2: {
    objectFit: "contain",
    marginBottom: 2,
    height: 19,
    width: 75,
    [theme.breakpoints.down("lg")]: {
      width: 60,
      height: 21,
    },
  },
  logo2_1: {
    width: 65,
    height: 20,
    marginBottom: 2,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 50,
    },
  },
  logo2_2: {
    width: 45,
    height: 20,
    marginBottom: 2,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 32,
    },
  },
  firstBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "7vw",
    height: 40,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 60,
      marginRight: 2,
    },
  },
  secondBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "8vw",
    height: 40,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 70,
      marginRight: 2,
    },
  },
  thirdBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "8vw",
    height: 40,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 70,
      marginRight: 2,
    },
  },
  fourthBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "4vw",
    height: 40,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
  },
  amountText1: {
    color: "transparent",
    fontSize: 12,
    fontWeight: "700",
    marginTop: 2,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  amountText2: {
    color: theme.palette.white,
    fontSize: 12,
    fontWeight: "700",
    marginTop: 2,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  logoBox3: {
    position: "absolute",
    left: 85,
    right: 0,
    top: -155,
    bottom: 0,
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      left: 0,
      right: 0,
      top: -155,
      bottom: 0,
    },
  },
  logo3: {
    zIndex: 3,
    width: 230,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 190,
    },
  },
  gridContainer: {
    width: "100%",
    height: "auto",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
    },
  },
  gridBox: {
    width: "100%",
    height: "auto",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box3: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: 13,
    height: "auto",
    minHeight: "50vh",
    backgroundColor: "rgba(27, 25, 93, 0.6)",
    border: `3px solid ${theme.palette.borderPupple}`,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 30,
      paddingBottom: 30,
      height: "auto",
      width: "100%",
      maxWidth: "100%",
    },
  },
  box4: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    paddingBottom: 20,
    height: "auto",
    backgroundColor: "rgba(27, 25, 93, 0.6)",
    border: `3px solid #c1bfff`,
    borderRadius: 20,
    [theme.breakpoints.down("lg")]: {
      marginTop: 20,
      //   marginTop: 100,
      //   paddingLeft: 0,
      //   paddingRight: 0,
      //   paddingTop: 30,
      //   paddingBottom: 30,
      //   height: "auto",
      //   width: "100%",
      //   maxWidth: "100%",
    },
  },

  logoBox4: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo4: {
    zIndex: 4,
    width: "38vw",
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 270,
    },
  },
  gridItem1: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      display: "inline",
    },
  },
  logoBox5: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo5: {
    width: 220,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
  },
  logoBox6: {
    display: "flex",
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo6: {
    width: 110,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 120,
    },
  },
  logoBox7: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo7: {
    width: 25,
    objectFit: "contain",
    marginRight: 12,
    [theme.breakpoints.down("lg")]: {
      width: 25,
      marginRight: 10,
    },
  },
  logoText7: {
    color: theme.palette.mintPupple,
    fontSize: 27,
    fontWeight: "700",
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
  },
  logoBox8: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo8: {
    width: 130,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 145,
    },
  },
  logoBox9: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo9: {
    width: 25,
    objectFit: "contain",
    marginRight: 12,
    [theme.breakpoints.down("lg")]: {
      width: 25,
      marginRight: 10,
    },
  },
  logoText9: {
    color: theme.palette.white,
    fontSize: 27,
    fontWeight: "700",
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
  },
  logoBox10: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logoText10: {
    color: theme.palette.white,
    fontFamily: "sans-serif",
    fontWeight: "400",
    fontSize: 12,
    width: 380,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
      width: 280,
      textAlign: "center",
      fontWeight: "300",
    },
  },
  buttonBox1: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "space-around",
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 25,
    },
  },

  buttonImage: {
    width: 160,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 140,
    },
  },
  dividerBox: {
    marginTop: 35,
    paddingRight: 50,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  divider: {
    height: 250,
    backgroundColor: theme.palette.borderGrey,
  },
  gridItem2: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
      display: "inline",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  logo10: {
    width: 60,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
  },
  box11: {
    display: "flex",
    marginTop: 25,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logoBox11: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      marginTop: 20,
    },
  },
  logo11: {
    height: 20,
    objectFit: "contain",
    marginRight: 10,
    [theme.breakpoints.down("lg")]: {
      height: 20,
    },
  },
  logoText11: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: "500",
    width: "100%",
    marginRight: 25,
  },
  logoBox13: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 70,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 40,
    },
  },
  logo13: {
    width: 90,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 20,
    },
  },
  buttonImage2: {
    width: 35,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
  },
  amountText: {
    color: theme.palette.white,
    fontSize: 36,
    fontWeight: "700",
    marginLeft: 70,
    marginRight: 70,
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
  },
  logoBox14: {
    display: "flex",
    marginTop: 30,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logoBox15: {
    display: "flex",
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      marginTop: 25,
    },
  },
  buttonImage3: {
    height: 48,
    objectFit: "contain",
    cursor: "pointer",
    "&:hover": {},
    [theme.breakpoints.down("lg")]: {
      height: "auto",
      width: "100%",
    },
  },
  imageButton: {
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

export default Mint;
