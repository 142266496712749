import React from "react";
import Caver from "caver-js";
import { makeStyles, withStyles } from "@mui/styles";
import { Typography, Box, Divider, Grid, CardMedia, Button, Slider, CircularProgress } from "@mui/material";
import Page from "components/Page";
import Loader from "components/Loader";
import HomeHeader from "components/Home/HomeHeader";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import useMediaQuery from "@mui/material/useMediaQuery";
import ABI from "resources/abi/v_abi";
import { runContract } from "utils";
import Countdown from "react-countdown";

import BackgroundImage from "resources/Images/BackgroundMintPage.jpeg";
import Logo1 from "resources/Images/Mint/Logo1.png";
import Logo2 from "resources/Images/Mint/Logo2.png";
import Logo2_1 from "resources/Images/Mint/Logo2_1.png";
import Logo2_2 from "resources/Images/Mint/Logo2_2.png";
import Logo2_3 from "resources/Images/Mint/Logo2_3.png";
import Logo3 from "resources/Images/Mint/Logo3.png";
import Logo4 from "resources/Images/Mint/Logo4.png";
import Logo5 from "resources/Images/Mint/Logo5.png";
import Logo6 from "resources/Images/Mint/Logo6.png";
import Logo7 from "resources/Images/Mint/Logo7.png";
import Logo8 from "resources/Images/Mint/Logo8.png";
import Logo9 from "resources/Images/Mint/Logo9.png";
import Button1 from "resources/Images/Mint/Button1.png";
import Button2 from "resources/Images/Mint/Button2.png";
import Logo10 from "resources/Images/Mint/Logo10.png";
import Logo11 from "resources/Images/Mint/Logo11.png";
import Logo12 from "resources/Images/Mint/Logo12.png";
import Logo13 from "resources/Images/Mint/Logo13.png";
import Image1 from "resources/Images/구성 요소 112 – 1@3x.png";
import PlusButton from "resources/Images/Mint/PlusButton.png";
import MinusButton from "resources/Images/Mint/MinusButton.png";
import BuyButton from "resources/Images/Mint/BuyButton.png";
import { useSnackbar } from "notistack";
import GlobalContext from "context/GlobalContext";
import moment from "moment";

const Mint = ({ history }) => {
  const caver = new Caver(window.klaytn);
  const [startDate, setStartDate] = React.useState(null);

  const [amount, setAmount] = React.useState(0);
  const [callResult, setCallResult] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [config, setConfig] = React.useState(null);
  const [isPublic, setIsPublic] = React.useState(false);
  const { walletVerification, network } = React.useContext(GlobalContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const mdView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const toggleFixedRef = React.useRef(false);
  const toggleUnFixedRef = React.useRef(false);
  const togglePublicRef = React.useRef(false);

  const [mSwitch, setMSwitch] = React.useState(true);

  const onClickAmount = (value) => {
    if (
      (Number(amount) === 0 && value === "minus") ||
      (Number(amount) === Number(config.perWallet) && value === "plus")
    ) {
      return;
    }

    switch (value) {
      case "plus": {
        return setAmount(() => Number(amount) + 1);
      }
      case "minus": {
        return setAmount(() => Number(amount) - 1);
      }
      default: {
        throw Error();
      }
    }
  };

  const buyOnClick = async () => {
    if (Number(network) !== Number(process.env.REACT_APP_MAINCHAIN)) {
      return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
    }
    if (amount === 0) {
      return enqueueSnackbar(t("올바른 수량이 아닙니다."), { variant: "error" });
    }
    const caver = new Caver(window.klaytn);
    const balance = Number(caver.utils.fromWei(await caver.klay.getBalance(window.klaytn.selectedAddress)));
    const priceWei = Number(amount) * Number(config.priceNow);
    if (balance < priceWei) {
      return enqueueSnackbar(t("잔액이 부족합니다."), { variant: "error" });
    }
    const requestedPrice = caver.utils.toWei(priceWei.toString());

    const run = await contractCall({
      method: "mintV",
      input: [{ value: amount }],
      type: "deploy",
      value: requestedPrice.toString(),
    });
  };
  /**
   * 1. 리밋에 맞게 수량 수정(딱 걸치는애들)
   * 2. 리밋 전부 차면 amount값도 그에따라 바뀌게(안올라가있게비활성화ㄴ)
   *
   */

  const contractCall = async ({ type = "call", method, input = [], value = 0 }) => {
    const caver = new Caver(window.klaytn);
    const mapInput = input.map((item) => {
      return item.value;
    });
    const run = await runContract({
      abi: ABI,
      caver,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
      type,
      from: window.klaytn.selectedAddress,
      method,
      input: mapInput,
      value,
    });
    if (run.result === "error") {
      return enqueueSnackbar(t("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요."), { variant: "error" });
    }

    if (type === "call") {
      const json = { ...callResult };
      json[method] = run.result;
      setCallResult(json);
      return run.result;
    }

    const transactionParameters = {
      from: window.klaytn.selectedAddress, // must match user's active address.
      data: run.result.deploy,
      value: caver.utils.toHex(value),
    };
    transactionParameters["to"] = process.env.REACT_APP_CONTRACT_ADDRESS;
    try {
      window.klaytn.sendAsync(
        {
          method: "klay_sendTransaction",
          params: [transactionParameters],
        },
        (err, result) => {
          initialize();
        }
      );
    } catch (e) {
      enqueueSnackbar(t("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요."), { variant: "error" });
    }
  };

  const initialize = async () => {
    if (window.klaytn) {
      if (window.klaytn.networkVersion !== Number(process.env.REACT_APP_MAINCHAIN)) {
        return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
      }
      const caver = new Caver(window.klaytn);
      const blockNumber = await caver.klay.getBlockNumber();
      const getConfig = await contractCall({ method: "getConfig" });
      const getPerWallet = await contractCall({ method: "getPerWallet" });
      // const getPerWallet = 5;
      const getPriceNow = await contractCall({ method: "getPriceNow" });
      setAmount(Number(getPerWallet));
      setConfig({
        fixedLimit: getConfig[0],
        fixedStartBlock: getConfig[1],
        fixedEndBlock: getConfig[2],
        unFixedLimit: getConfig[3],
        unFixedTxLimit: getConfig[4],
        unFixedStartBlock: getConfig[5],
        unFixedEndBlock: getConfig[6],
        publicLimit: getConfig[7],
        publicTxLimit: getConfig[8],
        publicMintPrice: getConfig[9],
        publicStartBlock: getConfig[10],
        startBlock: getConfig[11],
        mintIndex: getConfig[12],
        blockNumber,
        priceNow: caver.utils.fromWei(getPriceNow),
        perWallet: getPerWallet,
        adminIndex: getConfig[13],
      });
    }
  };

  const initEffect = async () => {
    const blockNumber = await caver.klay.getBlockNumber();
    const getConfig = await contractCall({ method: "getConfig" });
    // const getPerWallet = 5;
    const getPerWallet = await contractCall({ method: "getPerWallet" });
    const getPriceNow = await contractCall({ method: "getPriceNow" });

    if (Number(getConfig[1]) <= blockNumber && blockNumber < Number(getConfig[2]) && toggleFixedRef.current) {
      setAmount(getPerWallet);
      toggleFixedRef.current = false;
    } else if (Number(getConfig[5]) <= blockNumber && blockNumber < Number(getConfig[6]) && toggleUnFixedRef.current) {
      setAmount(getPerWallet);
      toggleUnFixedRef.current = false;
    } else if (Number(getConfig[10]) <= blockNumber && togglePublicRef.current) {
      setAmount(getPerWallet);
      togglePublicRef.current = false;
    }

    setConfig(() => ({
      fixedLimit: getConfig[0],
      fixedStartBlock: getConfig[1],
      fixedEndBlock: getConfig[2],
      unFixedLimit: getConfig[3],
      unFixedTxLimit: getConfig[4],
      unFixedStartBlock: getConfig[5],
      unFixedEndBlock: getConfig[6],
      publicLimit: getConfig[7],
      publicTxLimit: getConfig[8],
      publicMintPrice: getConfig[9],
      publicStartBlock: getConfig[10],
      startBlock: getConfig[11],
      mintIndex: getConfig[12],
      blockNumber,
      priceNow: caver.utils.fromWei(getPriceNow),
      perWallet: getPerWallet,
      adminIndex: getConfig[13],
    }));
  };

  useEffectOnce(() => {
    (async () => {
      setInterval(() => {
        initEffect();
      }, 1000);
      const caver = new Caver(window.klaytn);
      await walletVerification();
      await initialize();

      const getConfig = await contractCall({ method: "getConfig" });
      const nowBlockNumber = await caver.klay.getBlockNumber();

      if (nowBlockNumber < Number(getConfig[1]) && !toggleFixedRef.current) {
        setStartDate(moment("2022-04-05 19:00:00").format("YYYY-MM-DD HH:mm:ss"));
        toggleFixedRef.current = true;
      } else if (nowBlockNumber < Number(getConfig[2]) && !toggleFixedRef.current) {
        setStartDate(moment("2022-04-06 19:00:00").format("YYYY-MM-DD HH:mm:ss"));
        toggleFixedRef.current = true;
      } else if (nowBlockNumber < Number(getConfig[5]) && !toggleUnFixedRef.current) {
        setStartDate(moment("2022-04-06 20:00:00").format("YYYY-MM-DD HH:mm:ss"));
        toggleUnFixedRef.current = true;
        setMSwitch(true);
      } else if (nowBlockNumber < Number(getConfig[6]) && !toggleUnFixedRef.current) {
        setStartDate(moment("2022-04-07 19:00:00").format("YYYY-MM-DD HH:mm:ss"));
        toggleUnFixedRef.current = true;
        setMSwitch(false);
      } else if (nowBlockNumber < Number(getConfig[10]) && !togglePublicRef.current) {
        setStartDate(moment("2022-04-07 20:00:00").format("YYYY-MM-DD HH:mm:ss"));
        setMSwitch(true);
        togglePublicRef.current = true;
      } else if (Number(getConfig[10]) < nowBlockNumber) {
        setStartDate(moment("2022-04-15 14:00:00").format("YYYY-MM-DD HH:mm:ss"));
        togglePublicRef.current = true;
        setMSwitch(false);
      }

      // caver.klay.subscribe("newBlockHeaders", async (error, block) => {
      //   // const getConfig = await contractCall({ method: "getConfig" });
      //   // const { number: nowBlockNumber } = block;
      //   initEffect();
      // });
    })();
  });

  return (
    <Page title={"Mint"}>
      <React.Fragment>
        <HomeHeader />
        <Box className={classes.container}>
          <CardMedia className={classes.backgroundImage} component="img" image={BackgroundImage} />
          {config ? (
            <Box className={classes.box}>
              <Box sx={{ maxWidth: "80%" }}>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.flexBox}>
                    {/* {!isPublic && (
                      <Box style={{ height: 142 }} className={classes.box4}>
                        <Typography sx={{ fontWeight: "bold", color: "#ffaedf", fontSize: 30, textAlign: "center" }}>
                          {mSwitch ? "Start Public" : "Minting Countdown"}
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", color: "#ff5aa7", fontSize: 34 }}>
                          {startDate ? (
                            <Countdown
                              onComplete={async () => {
                                setStartDate(moment("2022-04-15 14:00:00").format("YYYY-MM-DD HH:mm:ss"));
                                setMSwitch(false);
                              }}
                              key={startDate}
                              // onTick={initEffect}
                              date={startDate}
                            ></Countdown>
                          ) : (
                            <CircularProgress size={15} color="white" />
                          )}
                        </Typography>
                      </Box>
                    )} */}
                    <Box className={classes.logoBox1}>
                      <Box className={classes.logoTitleBox}>
                        <CardMedia className={classes.logo1} component="img" image={Logo1} />
                      </Box>
                      <Box className={classes.box2}>
                        <Typography className={classes.amountNumber} mr={1.5}>
                          {Number(config.mintIndex) - Number(config.adminIndex)}
                        </Typography>
                        <Box className={classes.sliderBox}>
                          <CustomSlider
                            aria-label="Custom marks"
                            value={Number(config.mintIndex) - Number(config.adminIndex)}
                            min={0}
                            max={Number(config.fixedLimit) + Number(config.unFixedLimit) + Number(config.publicLimit)}
                            // step={10}
                            valueLabelDisplay="auto"
                            marks={[
                              {
                                value: 0,
                                label: <CardMedia className={classes.logo2_1} component="img" image={Logo2_3} />,
                              },
                              {
                                value:
                                  (Number(config.fixedLimit) +
                                    Number(config.unFixedLimit) +
                                    Number(config.publicLimit)) /
                                  2,
                                label: <CardMedia className={classes.logo2} component="img" image={Image1} />,
                              },
                              {
                                value:
                                  Number(config.fixedLimit) + Number(config.unFixedLimit) + Number(config.publicLimit),
                                label: (
                                  <CardMedia className={classes.logo2_2} component="img" image={Logo2} sx={{ ml: 2 }} />
                                ),
                              },
                            ]}
                            // disableSwap={true}
                          />
                        </Box>

                        {/* <Box>
                        <CardMedia className={classes.logo2_2} component="img" image={Logo2} sx={{ ml: 2 }} />
                        <Box className={classes.firstBlock}></Box>
                        <Typography className={classes.amountText1}>0</Typography>
                      </Box>

                      <Box>
                        <CardMedia className={classes.logo2} component="img" image={Logo2_1} />
                        <Box className={classes.secondBlock}></Box>
                        <Typography className={classes.amountText1}>0</Typography>
                      </Box>

                      <Box>
                        <CardMedia className={classes.logo2} component="img" image={Logo2_2} />
                        <Box className={classes.thirdBlock}></Box>
                        <Typography className={classes.amountText1}>0</Typography>
                      </Box>

                      <Box>
                        <CardMedia className={classes.logo2_1} component="img" image={Logo2_3} />
                        <Box className={classes.fourthBlock}></Box>
                        <Typography className={classes.amountText2}>{t("AMOUNT")}</Typography>
                      </Box> */}

                        <Typography className={classes.amountNumber} ml={mdView ? 0 : 1.5}>
                          {Number(config.fixedLimit) + Number(config.unFixedLimit) + Number(config.publicLimit)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Grid container className={classes.gridContainer}>
                  <Box className={classes.gridBox}>
                    <Box className={classes.box3}>
                      <Box>
                        <Box className={classes.logoBox3}>
                          {/* <CardMedia className={classes.logo3} component="img" image={Logo3} /> */}
                        </Box>
                        <Box className={classes.logoBox4}>
                          <CardMedia className={classes.logo4} component="img" image={Logo4} />
                        </Box>

                        <Grid container>
                          <Grid className={classes.gridItem1} item xl={5.7} lg={5.7} md={12} sm={12} xs={12}>
                            <Box>
                              <Box className={classes.logoBox5}>
                                <CardMedia className={classes.logo5} component="img" image={Logo5} />
                              </Box>

                              <Box className={classes.logoBox6}>
                                <CardMedia className={classes.logo6} component="img" image={Logo6} />
                              </Box>
                              <Box className={classes.logoBox7}>
                                <CardMedia className={classes.logo7} component="img" image={Logo7} />
                                <Typography className={classes.logoText7}>#{config.blockNumber}</Typography>
                              </Box>

                              <Box className={classes.logoBox8}>
                                <CardMedia className={classes.logo8} component="img" image={Logo8} />
                              </Box>
                              <Box className={classes.logoBox9}>
                                <CardMedia className={classes.logo9} component="img" image={Logo9} />
                                <Typography className={classes.logoText9}>#{config.publicStartBlock}</Typography>
                              </Box>

                              <Box className={classes.logoBox9}>
                                <Typography className={classes.logoText10}>
                                  {t("보다 정확한 현재 블록 정보는  클레이튼 스코프 or 클레이스왑을 참고 바랍니다.")}
                                </Typography>
                              </Box>
                              <Box className={classes.logoBox10}>
                                <Typography className={classes.logoText10}>
                                  {t(
                                    "More accurate current block information Greander Scots OR Glare Note please. Move through the bottom button below."
                                  )}
                                </Typography>
                              </Box>

                              <Box className={classes.buttonBox1}>
                                <Box mr={1}>
                                  <Button
                                    onClick={() => window.open(`https://scope.klaytn.com/`)}
                                    className={classes.imageButton}
                                  >
                                    <CardMedia className={classes.buttonImage} component="img" image={Button1} />
                                  </Button>
                                </Box>
                                <Box ml={1}>
                                  <Button
                                    onClick={() => window.open(`https://klayswap.com/`)}
                                    className={classes.imageButton}
                                  >
                                    <CardMedia className={classes.buttonImage} component="img" image={Button2} />
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid className={classes.gridItem2} item xl={6.3} lg={6.3} md={12} sm={12} xs={12}>
                            <Box className={classes.dividerBox}>
                              {!mdView && (
                                <Divider orientation="vertical" variant="middle" className={classes.divider} />
                              )}
                            </Box>

                            <Box>
                              <Box className={classes.logoBox6}>
                                <CardMedia className={classes.logo10} component="img" image={Logo10} />
                              </Box>

                              <Box className={classes.logoBox9}>
                                <CardMedia className={classes.logo9} component="img" image={Logo9} />
                                <Typography className={classes.logoText9}>{config.priceNow} KLAY</Typography>
                              </Box>

                              <Box className={classes.box11}>
                                <Box className={classes.logoBox11}>
                                  <CardMedia className={classes.logo11} component="img" image={Logo11} />
                                  <Typography className={classes.logoText11}>#{config.publicStartBlock}</Typography>
                                </Box>
                                <Box className={classes.logoBox11}>
                                  <CardMedia className={classes.logo11} component="img" image={Logo12} />
                                  <Typography className={classes.logoText11}>{config.perWallet}</Typography>
                                </Box>
                              </Box>

                              <Box className={classes.logoBox13}>
                                <CardMedia className={classes.logo13} component="img" image={Logo13} />
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                  <Button className={classes.imageButton}>
                                    <CardMedia
                                      onClick={onClickAmount.bind(null, "minus")}
                                      className={classes.buttonImage2}
                                      component="img"
                                      image={MinusButton}
                                    />
                                  </Button>
                                  <Typography className={classes.amountText}>{amount}</Typography>
                                  <Button className={classes.imageButton}>
                                    <CardMedia
                                      onClick={onClickAmount.bind(null, "plus")}
                                      className={classes.buttonImage2}
                                      component="img"
                                      image={PlusButton}
                                    />
                                  </Button>
                                </Box>
                              </Box>

                              <Box className={classes.logoBox14}>
                                <Typography className={classes.logoText10}>
                                  민팅은 새로고침 없이 진행됩니다. MINTING PROCEEDS WITHOUT A REFRESH
                                </Typography>
                              </Box>

                              <Box className={classes.logoBox15}>
                                <Button onClick={buyOnClick} className={classes.imageButton}>
                                  <CardMedia className={classes.buttonImage3} component="img" image={BuyButton} />
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Loader></Loader>
          )}
        </Box>
      </React.Fragment>
    </Page>
  );
};

const CustomSlider = withStyles((theme) => ({
  track: {
    // opacity: 0,
    height: 35,
    backgroundColor: theme.palette.lightPupple,
    borderRadius: 15,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rail: {
    backgroundColor: theme.palette.slider,
    borderRadius: 15,
    height: 35,
    // width: "100%",
    // opacity: 0.9,
    // border: `1px solid ${theme.palette.white}`,
  },
  thumb: {
    // display: "none",
    backgroundColor: theme.palette.white,
    height: 34,
    width: 34,
    // [theme.breakpoints.down("sm")]: {
    //   height: 16,
    //   width: 16,
    // },
  },
  mark: {
    display: "none",
    // height: 35,
  },
  markLabel: {
    marginTop: 10,
  },
  valueLabel: {
    backgroundColor: theme.palette.lightPupple,
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.black,
    position: "relative",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
    // [theme.breakpoints.down("lg")]: {
    //   display: "none",
    //   width: "none",
    //   height: "none",
    // },
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  flexBox: {
    zIndex: 5,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 30,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  },
  logoBox: {
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 20,
      marginLeft: 0,
    },
  },
  logo1: {
    width: 250,
    objectFit: "contain",
    marginBottom: 20,
    [theme.breakpoints.down("lg")]: {
      width: 250,
      marginBottom: 30,
    },
  },
  box2: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  sliderBox: {
    marginLeft: 10,
    marginRight: 10,
    width: 320,
    [theme.breakpoints.down("lg")]: {
      marginLeft: 10,
      marginRight: 20,
      width: 230,
    },
  },
  logoBox1: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {},
  },
  logoTitleBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "center",
    },
  },

  amountNumber: {
    marginTop: -4,
    color: theme.palette.lightPupple,
    fontSize: 26,
    fontWeight: "500",
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
      marginTop: 10,
    },
  },
  logo2: {
    objectFit: "contain",
    marginBottom: 2,
    height: 19,
    width: 75,
    [theme.breakpoints.down("lg")]: {
      width: 60,
      height: 21,
    },
  },
  logo2_1: {
    width: 65,
    height: 20,
    marginBottom: 2,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 50,
    },
  },
  logo2_2: {
    width: 45,
    height: 20,
    marginBottom: 2,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 32,
    },
  },
  firstBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "7vw",
    height: 40,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 60,
      marginRight: 2,
    },
  },
  secondBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "8vw",
    height: 40,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 70,
      marginRight: 2,
    },
  },
  thirdBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "8vw",
    height: 40,
    marginRight: 5,
    [theme.breakpoints.down("lg")]: {
      width: 70,
      marginRight: 2,
    },
  },
  fourthBlock: {
    backgroundColor: theme.palette.lightPupple,
    width: "4vw",
    height: 40,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
  },
  amountText1: {
    color: "transparent",
    fontSize: 12,
    fontWeight: "700",
    marginTop: 2,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  amountText2: {
    color: theme.palette.white,
    fontSize: 12,
    fontWeight: "700",
    marginTop: 2,
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  logoBox3: {
    position: "absolute",
    left: 85,
    right: 0,
    top: -155,
    bottom: 0,
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      left: 0,
      right: 0,
      top: -155,
      bottom: 0,
    },
  },
  logo3: {
    zIndex: 3,
    width: 230,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 190,
    },
  },
  gridContainer: {
    width: "100%",
    height: "auto",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
    },
  },
  gridBox: {
    width: "100%",
    height: "auto",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box3: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: 13,
    height: "auto",
    minHeight: "50vh",
    backgroundColor: "rgba(27, 25, 93, 0.6)",
    border: `3px solid ${theme.palette.borderPupple}`,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 30,
      paddingBottom: 30,
      height: "auto",
      width: "100%",
      maxWidth: "100%",
    },
  },
  box4: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    paddingBottom: 20,
    height: "auto",
    backgroundColor: "rgba(27, 25, 93, 0.6)",
    border: `3px solid #c1bfff`,
    borderRadius: 20,
    [theme.breakpoints.down("lg")]: {
      marginTop: 20,
      //   marginTop: 100,
      //   paddingLeft: 0,
      //   paddingRight: 0,
      //   paddingTop: 30,
      //   paddingBottom: 30,
      //   height: "auto",
      //   width: "100%",
      //   maxWidth: "100%",
    },
  },

  logoBox4: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo4: {
    zIndex: 4,
    width: "38vw",
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 270,
    },
  },
  gridItem1: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      display: "inline",
    },
  },
  logoBox5: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo5: {
    width: 220,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 200,
    },
  },
  logoBox6: {
    display: "flex",
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo6: {
    width: 110,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 120,
    },
  },
  logoBox7: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo7: {
    width: 25,
    objectFit: "contain",
    marginRight: 12,
    [theme.breakpoints.down("lg")]: {
      width: 25,
      marginRight: 10,
    },
  },
  logoText7: {
    color: theme.palette.mintPupple,
    fontSize: 27,
    fontWeight: "700",
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
  },
  logoBox8: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo8: {
    width: 130,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 145,
    },
  },
  logoBox9: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logo9: {
    width: 25,
    objectFit: "contain",
    marginRight: 12,
    [theme.breakpoints.down("lg")]: {
      width: 25,
      marginRight: 10,
    },
  },
  logoText9: {
    color: theme.palette.white,
    fontSize: 27,
    fontWeight: "700",
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
  },
  logoBox10: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logoText10: {
    color: theme.palette.white,
    fontFamily: "sans-serif",
    fontWeight: "400",
    fontSize: 12,
    width: 380,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
      width: 280,
      textAlign: "center",
      fontWeight: "300",
    },
  },
  buttonBox1: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "space-around",
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 25,
    },
  },

  buttonImage: {
    width: 160,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 140,
    },
  },
  dividerBox: {
    marginTop: 35,
    paddingRight: 50,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  divider: {
    height: 250,
    backgroundColor: theme.palette.borderGrey,
  },
  gridItem2: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("lg")]: {
      marginTop: 30,
      display: "inline",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  logo10: {
    width: 60,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
  },
  box11: {
    display: "flex",
    marginTop: 25,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logoBox11: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      marginTop: 20,
    },
  },
  logo11: {
    height: 20,
    objectFit: "contain",
    marginRight: 10,
    [theme.breakpoints.down("lg")]: {
      height: 20,
    },
  },
  logoText11: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: "500",
    width: "100%",
    marginRight: 25,
  },
  logoBox13: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 70,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 40,
    },
  },
  logo13: {
    width: 90,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      marginBottom: 20,
    },
  },
  buttonImage2: {
    width: 35,
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
  },
  amountText: {
    color: theme.palette.white,
    fontSize: 36,
    fontWeight: "700",
    marginLeft: 70,
    marginRight: 70,
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
  },
  logoBox14: {
    display: "flex",
    marginTop: 30,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  logoBox15: {
    display: "flex",
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      marginTop: 25,
    },
  },
  buttonImage3: {
    height: 48,
    objectFit: "contain",
    cursor: "pointer",
    "&:hover": {},
    [theme.breakpoints.down("lg")]: {
      height: "auto",
      width: "100%",
    },
  },
  imageButton: {
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

export default Mint;
