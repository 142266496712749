import React from "react";
import { useEffectOnce } from "react-use";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Element } from "react-scroll";
import { Helmet } from "react-helmet";
import "./assets/css/plugin/swiper.min.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import $ from "jquery";

// import Page from "components/Page";
// import Loader from "components/Loader";

// import HomeHeader from "components/Home/HomeHeader";
// import HomeFirstLayer from "components/Home/HomeFirstLayer";
// import HomeSecondLayer from "components/Home/HomeSecondLayer";
// import HomeThirdLayer from "components/Home/HomeThirdLayer";
// import HomeFourthLayer from "components/Home/HomeFourthLayer";
// import HomeFifthLayer from "components/Home/HomeFifthLayer";
// // import HomeSixthLayer from "components/Home/HomeSixthLayer";
// import HomeSeventhLayer from "components/Home/HomeSeventhLayer";
// import Footer from "components/Home/Footer";

// import HtmlPage from "./main/index.html";
// const htmlDoc = { __html: HtmlPage };
const Home = ({ history }) => {
  const [windowW, setWindowW] = React.useState("");
  const classes = useStyles();
  const loadFn = () => {
    $(window).scrollTop();
    setWindowW(window.innerWidth);

    clickDefaultFn();
    menuClickFn();
    mobileMenuClickFn();
    logoClickFn();
    menuConnectBtnFn();
    swiperSlideFn();
  };
  const resizeFn = () => {
    $(window).resize(function () {
      setWindowW(window.innerWidth);
    });
  };
  const scrollFn = () => {
    $(window).scroll(function () {
      $(this).scrollTop();
    });
  };
  const clickDefaultFn = () => {
    $('a[href="#"]').click(function (e) {
      e.preventDefault();
    });
  };
  const menuClickFn = () => {
    let $headerGnbObj = $(".header .gnb li");
    let moMenu = $(".header .moMenu");
    let dimd = $(".header .dimd");

    $headerGnbObj.on("click", function () {
      let headerGnbTxt = $(this).children("a").text().toLowerCase();
      let target = $(".contents").find("." + headerGnbTxt);
      let headerHeight = $(".header").innerHeight();
      let tooltip = $(this).parents(".header").find(".tooltip");

      $headerGnbObj.removeClass("on");
      $(this).addClass("on");

      if ($(this).hasClass("ready")) {
        if (tooltip.css("display") === "none") {
          tooltip.addClass("show");
        } else {
          tooltip.removeClass("show");
        }
      } else {
        let offsetTop = target.offset().top - headerHeight;

        $("html, body").animate({ scrollTop: offsetTop }, 500);

        if ($(this).parents(".moMenu")) {
          let tooltip = $(".header .tooltip");

          tooltip.removeClass("show");
          mobileMenuHideFn();
        }
      }
    });
  };
  const mobileMenuClickFn = () => {
    let moMenu = $(".header .moMenu");
    let dimd = $(".header .dimd");

    $(".header .moHeader .btnMenu").on("click", function () {
      if (moMenu.css("display") === "none") {
        moMenu.show().animate({ left: 0 }, 300);
        dimd.show();
        $("body").css("overflow", "hidden");
      } else {
        mobileMenuHideFn();
      }
    });
  };
  const logoClickFn = () => {
    $(".header h1 a").on("click", function () {
      let tooltip = $(".header .tooltip");

      $("html, body").animate({ scrollTop: 0 });
      tooltip.removeClass("show");
      mobileMenuHideFn();
    });
  };
  const mobileMenuHideFn = () => {
    let moMenu = $(".header .moMenu");
    let dimd = $(".header .dimd");

    moMenu.animate({ left: "-100%" }, 300, function () {
      $(this).hide();
      dimd.hide();
      $("body").css("overflow", "");
    });
  };
  const menuConnectBtnFn = () => {
    let buttonText = $(".util_menu button p");

    $(".util_menu button")
      .on("mouseover", function () {
        // buttonText.text("CONNECT OFF");
      })
      .on("mouseout", function () {
        buttonText.text("CONNECT ON");
      });
  };
  const swiperSlideFn = () => {
    // $(".gallerySwiper")
    // .on("mouseover", function () {
    //   gellerySwiper.autoplay.stop();
    //   gellerySwiper.allowTouchMove = true;
    // })
    // .on("mouseout", function () {
    //   gellerySwiper.autoplay.start();
    //   gellerySwiper.allowTouchMove = false;
    // });
  };

  useEffectOnce(() => {
    loadFn();
    resizeFn();
    scrollFn();
  });

  return (
    <body>
      <div style={{ backgroundColor: "#15161b" }} className="wrap">
        <header>
          <div className="header pc_only">
            <h1>
              <a href="#">
                <img src={require("./assets/img/gnb_logo.png").default} alt="" />
              </a>
            </h1>

            <nav className="nav">
              <ul className="gnb">
                <li>
                  <a href="#">STORY</a>
                </li>
                <li>
                  <a href="#">HISTORY</a>
                </li>
                <li>
                  <a href="#">ROADMAP</a>
                </li>
                <li>
                  <a href="#">MINING</a>
                </li>
                <li>
                  <a href="#">GALLERY</a>
                </li>
                <li>
                  <a href="#">TEAM</a>
                </li>
                <li>
                  <a href="#">PARTNERS</a>
                </li>
                <li className="ready">
                  <a href="#">GOVERNANCE</a>
                  <div className="tooltip">
                    <p>곧 공개됩니다!</p>
                  </div>
                </li>
              </ul>
            </nav>

            <div className="util_menu">
              <ul>
                <li>
                  <div style={{ cursor: "pointer" }} onClick={() => window.open("https://discord.gg/tHfY4cbUSM")}>
                    <img src={require("./assets/img/btn_discord.png").default} alt="디스코드" />
                  </div>
                </li>
                <li>
                  <div style={{ cursor: "pointer" }} onClick={() => window.open("https://twitter.com/RTKV_nft")}>
                    <img src={require("./assets/img/btn_twitter.png").default} alt="트위터" />
                  </div>
                </li>
              </ul>
              {/* <button type="button" onClick={() => history.push("/staking")}>
                <p>CONNECT ON</p>
              </button> */}
            </div>
          </div>

          <div className="header mo_only">
            <div className="moHeader">
              <h1>
                <a href="#">
                  <img src={require("./assets/img/gnb_logo.png").default} alt="" />
                </a>
              </h1>

              <a href="#" className="btnMenu">
                <img src={require("./assets/img/ico_menu.png").default} alt="" />
              </a>
            </div>
            <div className="moMenu">
              <ul className="gnb">
                <li>
                  <a href="#">STORY</a>
                </li>
                <li>
                  <a href="#">HISTORY</a>
                </li>
                <li>
                  <a href="#">ROADMAP</a>
                </li>
                <li>
                  <a href="#">MINING</a>
                </li>
                <li>
                  <a href="#">GALLERY</a>
                </li>
                <li>
                  <a href="#">TEAM</a>
                </li>
                <li>
                  <a href="#">PARTNERS</a>
                </li>
                <li className="ready">
                  <a href="#">GOVERNANCE</a>
                </li>
              </ul>
              <ul className="util">
                <li>
                  <div style={{ cursor: "pointer" }} onClick={() => window.open("https://discord.gg/tHfY4cbUSM")}>
                    <img src={require("./assets/img/btn_discord.png").default} alt="디스코드" />
                  </div>
                </li>
                <li>
                  <div style={{ cursor: "pointer" }} onClick={() => window.open("https://twitter.com/RTKV_nft")}>
                    <img src={require("./assets/img/btn_twitter.png").default} alt="트위터" />
                  </div>
                </li>
              </ul>
            </div>
            <div className="tooltip">
              <p>곧 공개됩니다!</p>
            </div>
            <div className="dimd"></div>
          </div>
        </header>

        <div className="contents">
          <div className="visual">
            <div className="txtCon">
              <div className="inner">
                <h2>
                  <img src={require("./assets/img/img_tit_01.png").default} alt="" className="pc_only" />
                  <img src={require("./assets/img/m_img_tit_01.png").default} alt="" className="mo_only" />
                </h2>
                <p>ROBOT TAEWON V PFP NFT</p>
                <span>
                  대한민국 전설의 로보트인 태권V는 <br className="mo_only" /> 1976, 1982, 1984, 1990, 2015년 까지{" "}
                  <br className="pc_only" />총 5가지의 버전으로 각각의 특성과 성격이 다른 태권V 10,000개가 발행된다.
                </span>
              </div>
            </div>
            <div className="visualBg">
              <video src={require("./assets/video/main_148.mp4").default} autoPlay muted loop preload playsInline />
            </div>
          </div>

          <div className="story">
            <div className="inner">
              <h2>
                <img src={require("./assets/img/img_tit_02.png").default} alt="" className="pc_only" />
                <img src={require("./assets/img/m_img_tit_02.png").default} alt="" className="mo_only" />
              </h2>
              <ul className="txtCon">
                <li>
                  <span className="num">#01</span>
                  <p>
                    한국 로보트 애니메이션의 시초 ‘로보트태권V’가 <br className="mo_only" /> 국내 최초 3D애니메이션
                    NFT로 돌아옵니다.
                    <br />
                    잠들어 있던 무적의 우리 영웅을 깨우고 <br className="mo_only" /> 새로운 ‘로보트태권V’와 함께 다시 한
                    번 전설을 만들어 가요.
                    <br />
                    일만개의 ‘로보트태권V’에 탑승할 수호자가 되어주세요.
                  </p>
                </li>
                <li>
                  <span className="num">#02</span>
                  <p>
                    1976년 한국 한국 최초의 극장판 애니메이션으로 등장한 <br className="mo_only" /> ‘로보트태권V’는
                    1982년 ‘슈퍼태권V를 거쳐,
                    <br />
                    일심동체로 움직이는 ’84태권V’로 업그레이드 되었고, <br className="mo_only" /> 1990년 전투무기를
                    탑재한 실사 합성 극장판 영화
                    <br />
                    ’90태권V’로 제작되었습니다. <br className="mo_only" /> 2015년에는 3D이미지의 베타버전
                    ‘마스터태권V’를 <br className="mo_only" /> 선보이기도 했습니다.
                    <br />
                    2022년, 이제 압도적인 비쥬얼로 구현된 <br className="mo_only" /> 일만개의 3D ‘로보트태권V’를
                    만나보세요.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="history">
            <div className="inner">
              <h2>
                <img src={require("./assets/img/img_tit_03.png").default} alt="" className="pc_only" />
                <img src={require("./assets/img/m_img_tit_03.png").default} alt="" className="mo_only" />
              </h2>
              <ul className="list">
                <li>
                  <div className="txtCon">
                    <h3>
                      <span>Origin</span>
                      <img src={require("./assets/img/history_txt_01.png").default} alt="1976" />
                    </h3>
                    <p>
                      태권V의 오리지날 버전 <br />
                      3D로 다시 태어난 압도적인 비주얼
                    </p>
                  </div>
                  <div className="imgCon">
                    <img src={require("./assets/img/history_img_01.png").default} alt="" />
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <h3>
                      <img src={require("./assets/img/history_txt_02.png").default} alt="1982" />
                      <span>슈퍼태권V</span>
                    </h3>
                    <p>
                      1976버전의 인간형 로보트 디자인을 벗어내고
                      <br className="pc_only" />더 정교하고 세련된 모습으로 업그레이드
                    </p>
                  </div>
                  <div className="imgCon">
                    <img src={require("./assets/img/history_img_02.png").default} alt="" />
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <h3>
                      <span>84태권V</span>
                      <img src={require("./assets/img/history_txt_03.png").default} alt="1984" />
                    </h3>
                    <p>
                      3단 분리합체라는 더욱 강력해진 모습으로
                      <br />
                      업그레이드 된 육중한 근육질 몸매
                    </p>
                  </div>
                  <div className="imgCon">
                    <img src={require("./assets/img/history_img_03.png").default} alt="" />
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <h3>
                      <img src={require("./assets/img/history_txt_04.png").default} alt="1990" />
                      <span>90태권V</span>
                    </h3>
                    <p>
                      실사 합성 애니메이션으로 등장한 90태권V!
                      <br className="pc_only" />
                      더욱 강력해진 <br className="mo_only" /> 중무장 메카닉 태권V
                    </p>
                  </div>
                  <div className="imgCon">
                    <img src={require("./assets/img/history_img_04.png").default} alt="" />
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <h3>
                      <span>마스터태권V</span>
                      <img src={require("./assets/img/history_txt_05.png").default} alt="2015" />
                    </h3>
                    <p>
                      76오리지널의 귀환!
                      <br />
                      정교하고 세련되게 3D 실제화의 초기모델
                    </p>
                  </div>
                  <div className="imgCon">
                    <img src={require("./assets/img/history_img_05.png").default} alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="roadmap">
            <div className="inner">
              <h2>
                <img src={require("./assets/img/img_tit_04.png").default} alt="" className="pc_only" />
                <img src={require("./assets/img/m_img_tit_04.png").default} alt="" className="mo_only" />
              </h2>
              <p className="subTit">민팅 달성율에 따른 로드맵</p>
              <ul className="list">
                <li className="active">
                  <div className="txtCon">
                    <p>Opensea 거래수수료 50% 분배</p>
                    <div className="graphs">
                      <span>0%</span>
                      <div className="bar" style={{ width: "0%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="active">
                  <div className="txtCon">
                    <p>거버넌스 실시</p>
                    <div className="graphs">
                      <span>5%</span>
                      <div className="bar" style={{ width: "5%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="active">
                  <div className="txtCon">
                    <p>태권V NFT 오프라인 전시회</p>
                    <div className="graphs">
                      <span>30%</span>
                      <div className="bar" style={{ width: "30%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <p>태권V 피규어 사업화 (30% 홀더분배)</p>
                    <div className="graphs">
                      <span>50%</span>
                      <div className="bar" style={{ width: "50%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <p>홀더 대상 NFT 에어드랍</p>
                    <div className="graphs">
                      <span>60%</span>
                      <div className="bar" style={{ width: "60%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <p>3D미디어 광고 엉뚱산수화 NFT 발행 (김청기감독)</p>
                    <div className="graphs">
                      <span>70%</span>
                      <div className="bar" style={{ width: "70%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <p>P2E/메타버스 일부 투자로 수익 창출</p>
                    <div className="graphs">
                      <span>80%</span>
                      <div className="bar" style={{ width: "80%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtCon">
                    <p>OTT 사업진출 (일부 투자로 수익창출 및 배분)</p>
                    <div className="graphs">
                      <span>100%</span>
                      <div className="bar" style={{ width: "100%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="fullList">
                  <div className="txtCon">
                    <p>V2 런칭</p>
                    <div className="graphs">
                      <span>100%</span>
                      <div className="bar" style={{ width: "100%" }}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="mining">
            <div className="inner">
              <h2>
                <img src={require("./assets/img/img_tit_05.png").default} alt="" className="pc_only" />
                <img src={require("./assets/img/m_img_tit_05.png").default} alt="" className="mo_only" />
              </h2>
              <div className="banner">
                <div className="txtCon">
                  <h3>Taekwon V 마이닝이란?</h3>
                  <p>
                    태권V NFT를 스테이킹하고 모든 태권V NFT홀더에게
                    <br />V 토큰 보상을 해주는 서비스 입니다.
                  </p>
                  <div onClick={() => history.push("/staking")} href="#" className="btn-enter">
                    <img src={require("./assets/img/btn_enter.png").default} alt="ENTER" />
                  </div>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://opensea.io/collection/official-robot-taekwon-v")}
                className="btn-purchase"
              >
                <a>Opensea 구매</a>
              </div>
            </div>
          </div>
          <div className="gallery">
            <h2>
              <img src={require("./assets/img/img_tit_06.png").default} alt="" className="pc_only" />
              <img src={require("./assets/img/m_img_tit_06.png").default} alt="" className="mo_only" />
            </h2>
            <div className="swiper gallerySwiper">
              <Slider
                responsive={[
                  {
                    breakpoint: 1800,
                    settings: {
                      slidesToShow: 4,
                    },
                  },
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ]}
                className="swiper-wrapper"
                dots={false}
                arrow={false}
                infinite={true}
                slidesToShow={5}
                autoplay={true}
                slidesToScroll={1}
                speed={3000}
                pauseOnHover
                autoplaySpeed={2000}
                cssEase="linear"
              >
                {[
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                  "13",
                  "14",
                  "15",
                  "16",
                  "17",
                  "18",
                  "19",
                  "20",
                  "21",
                  "22",
                  "23",
                  "24",
                  "25",
                ].map((item, key) => {
                  return (
                    <li key={key + "a_"} className="swiper-slide">
                      <div className="videoCon">
                        <video
                          src={require(`./assets/video/gallery_${item}.mp4`).default}
                          autoPlay
                          muted
                          loop
                          preload
                          playsInline
                        ></video>
                      </div>
                    </li>
                  );
                })}
              </Slider>
            </div>
          </div>

          <div className="teamPartner">
            <div className="inner">
              <div className="team">
                <h2>
                  <img src={require("./assets/img/img_tit_07.png").default} alt="" className="pc_only" />
                  <img src={require("./assets/img/m_img_tit_07.png").default} alt="" className="mo_only" />
                </h2>
                <ul className="list">
                  <li>
                    <div className="logo">
                      <img src={require("./assets/img/team_logo_nftmania.png").default} alt="" />
                    </div>
                    <span>Develpment</span>
                  </li>
                  <li>
                    <div className="logo">
                      <img src={require("./assets/img/team_logo_infinity.png").default} alt="" />
                    </div>
                    <span>Marketing & Planning</span>
                  </li>
                  <li>
                    <div className="logo">
                      <img src={require("./assets/img/team_logo_rush.png").default} alt="" />
                    </div>
                    <span>Tokenomics</span>
                  </li>
                  <li>
                    <div className="logo">
                      <img src={require("./assets/img/team_logo_kimchungki.png").default} alt="" />
                    </div>
                    <span>Director</span>
                  </li>
                </ul>
              </div>
              <div className="partners">
                <h2>
                  <img src={require("./assets/img/img_tit_08.png").default} alt="" className="pc_only" />
                  <img src={require("./assets/img/m_img_tit_08.png").default} alt="" className="mo_only" />
                </h2>
                <ul className="list">
                  <li>
                    <img src={require("./assets/img/partner_logo_metakongz.png").default} alt="" />
                  </li>
                  <li>
                    <img src={require("./assets/img/partner_logo_hobbyflex.png").default} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="inner">
            <p className="logo">
              <img src={require("./assets/img/footer_logo.png").default} alt="" />
            </p>
            <p className="copy">© 2022 by NFT MANIA & INFINITY MARKET ALL RIGHT RESERVED</p>
          </div>
        </div>
      </div>
    </body>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.black,
    width: "100%",
    height: "auto",
  },
}));

export default Home;

// <Page>
//   <HomeHeader />
//   <React.Fragment>
//     <Box className={classes.box}>
//       <HomeFirstLayer />
//       <Element name="Taekwon V">
//         <HomeSecondLayer />
//       </Element>
//       <HomeThirdLayer />
//       <Element name="Technologies">
//         <HomeFourthLayer />
//       </Element>
//       <Element name="Roadmap">
//         <HomeFifthLayer />
//       </Element>
//       {/* <HomeSixthLayer /> */}
//       <HomeSeventhLayer />
//       <Footer />
//     </Box>
//   </React.Fragment>
// </Page>
