import React from "react";
import AdminPage from "components/AdminPage";
import { useEffectOnce } from "react-use";
import axios from "axios";
import moment from "moment";
import { httpHeader } from "utils";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Box, Paper, useMediaQuery, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import GlobalContext from "context/GlobalContext";
import AdminGridHeader from "components/AdminGridHeader";

import { isFloat } from "utils";

const Admin = ({ location, history }) => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);

  const [columns, setColumns] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [rowsCount, setRowsCount] = React.useState(0);

  const [searchText, setSearchText] = React.useState("");

  const [selectColumn, setSelectColumn] = React.useState("id");
  const [selectPages, setSelectPages] = React.useState("1");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ fontSize: smView ? 12 : 16 });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { adminTables, user } = React.useContext(GlobalContext);

  const errorCase = (message) => {
    switch (message) {
      case "invalid table": {
        return enqueueSnackbar(t("어드민 메인 페이지 입니다."), { variant: "info" });
      }
      case "invalid column": {
        return enqueueSnackbar(t("컬럼이 선택되지 않았습니다."), { variant: "info" });
      }
      case "invalid pages": {
        return enqueueSnackbar(t("페이지를 올바르게 입력해주세요."), { variant: "info" });
      }
      case "disable update column": {
        return enqueueSnackbar(t("선택하신 셀은 변경할 수 없습니다."), { variant: "info" });
      }
      default: {
        return enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도해주세요."), { variant: "error" });
      }
    }
  };

  useEffectOnce(async () => {
    setLoading(true);

    try {
      const splitTable = location.pathname.split("/admin/")[1];
      const body = { table: splitTable };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body, httpHeader);

      if (!data.status) {
        return errorCase(data.message);
      }

      const { result } = data;

      const newColumns = result.columns.map((item) => {
        const columnsObject = {
          field: item.Field,
          headerName: item.Field,
          flex: 1,
          // cellClassName: "cells",
          headerClassName: "columnsHeader",
          renderHeader: (params) => {
            return (
              <Box className={classes.renderCellBox}>
                <Typography className={classes.renderCellValue} sx={{ fontWeight: "900", fontSize: 12 }}>
                  {params.field}
                </Typography>
              </Box>
            );
          },

          renderCell: (params) => {
            let value = params.value;
            if (params.colDef.valueFormatter(params)) {
              value = params.colDef.valueFormatter(params);
            }
            return (
              <Box className={classes.renderCellBox}>
                <CopyToClipboard
                  text={value}
                  onCopy={() => {
                    enqueueSnackbar(t("클립보드로 복사되었습니다."), { variant: "info" });
                  }}
                >
                  <Typography className={classes.renderCellValue} sx={{ fontSize: 12 }}>
                    {value}
                  </Typography>
                </CopyToClipboard>
              </Box>
            );
          },
          valueFormatter: (params) => {
            if (params.field === "created_at" || params.field === "last_login") {
              const newValue = moment(params.value).format("YYYY-MM-DD HH:mm:ss");
              return newValue;
            } else {
              return isFloat(params.value);
            }
          },
        };
        return columnsObject;
      });
      setColumns(newColumns);
      setRows(result.rows);
      setRowsCount(result.count);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  });

  const paging = React.useCallback(async () => {
    const splitTable = location.pathname.split("/admin/")[1];

    if (splitTable === undefined) {
      return;
    }

    setLoading(true);
    try {
      const body = {
        table: splitTable,
        page: page,
        perPage: perPage,
        selectColumn: selectColumn,
        searchText: searchText,
        startDate: startDate,
        endDate: endDate,
      };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body, httpHeader);
      const { result } = data;

      if (data.status) {
        setRows(result.rows);
        setRowsCount(result.count);
      } else {
        errorCase(data.message);
      }
    } catch (e) {
      errorCase(e.message);
    } finally {
      setLoading(false);
    }
  }, [page, perPage]);

  React.useEffect(() => {
    paging();
  }, [paging]);

  const searchOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const splitTable = location.pathname.split("/admin/")[1];
      const body = {
        table: splitTable,
        searchText: searchText,
        selectColumn: selectColumn,
        startDate: startDate,
        endDate: endDate,
      };
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/log", body, httpHeader);
      const { result } = data;

      if (data.status) {
        setRows(result.rows);
        setRowsCount(result.count);
      } else {
        errorCase(data.code);
      }
    } catch (e) {
      errorCase(e.message);
    } finally {
      setLoading(false);
    }
  };

  const pageOnSubmit = async (e) => {
    e.preventDefault();
    setPage(selectPages <= 0 ? 0 : selectPages - 1);
  };

  const paramsValue = {
    columns,
    searchOnSubmit,
    pageOnSubmit,
    searchText,
    setSearchText,
    selectColumn,
    setSelectColumn,
    selectPages,
    setSelectPages,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };

  return (
    <AdminPage header title={"Admin"}>
      <Paper style={{ paddingLeft: smView ? 0 : 240 }}>
        {columns && rows && !isNaN(Number(rowsCount)) && (
          <Box p={3}>
            <AdminGridHeader {...paramsValue} />

            <Box className={classes.gridContainer}>
              <DataGrid
                page={page}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
                pageSize={perPage}
                onPageSizeChange={(newSize) => {
                  setPerPage(newSize);
                }}
                loading={loading}
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginationMode="server"
                columns={columns}
                rows={rows}
                rowCount={rowsCount}
                // rowCount={rowsCount}
                disableSelectionOnClick={true}
                disableColumnMenu={true}
                components={{
                  ColumnSortedAscendingIcon: null,
                  ColumnSortedDescendingIcon: null,
                  ColumnUnsortedIcon: null,
                }}
                getCellClassName={(params) => {
                  if (params.colDef.updatable === true) {
                    return "unUpdateCells";
                  }
                  return "cells";
                }}
              />
            </Box>
          </Box>
        )}
      </Paper>
    </AdminPage>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    renderCellBox: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      textOverflow: "revert",
    },
    renderCellValue: {
      textAlign: "center",
      width: "100%",
    },

    gridContainer: {
      width: "100%",
      height: "80vh",
      "& .cells": {
        // backgroundColor: "red"
      },
      "& .columnsHeader": {
        // width: "100%",
        // height: "100%",
        // fontSize: 13,
        // textOverflow: "revert",
        // overflow: "auto",
        // color: "red",
        // textAlign: "center",
      },

      // "& .unUpdateCells": {
      //   width: "100%",
      //   overflow: "auto",
      //   textOverflow: "revert",
      //   backgroundColor: "#F1F0FF",
      // },
    },
  };
});

export default Admin;
