import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import GlobalContext from "context/GlobalContext";
import UserHome from "pages/Home";
import HomeRenewal from "components/Home/HomeRenewal";
import Wallet from "pages/Wallet";
import Mint from "pages/Mint";
import Mining from "pages/Mining";
import Staking from "pages/Staking";
import Admin from "pages/Admin";

import { useEffectOnce } from "react-use";
import Caver from "caver-js";
import ABI from "resources/abi/v_abi";
import axios from "axios";
import { httpHeader } from "utils";

const GuestRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={UserHome} />
      {/* <Route exact path="/" component={HomeRenewal} /> */}
      <Route exact path="/staking" component={Staking} />
      <Route exact path="/wallet" component={Wallet} />
      {/* <Route exact path="/staking" component={Staking} /> */}
      {/* <Route exact path="/mining" component={Mining} /> */}
      {/* <Route exact path="/mint-v" component={Mint} /> */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

// const AdminRoutes = () => {
//   const { adminTables, user } = React.useContext(GlobalContext);

//   return (
//     <Switch>
//       <Route exact path="/" component={UserHome} />
//       <Route exact path="/wallet" component={Wallet} />
//       {/* <Route exact path="/minting-v" component={Mint} /> */}
//       {user && (
//         <React.Fragment>
//           <Route exact path="/admin" component={Admin} />
//           {adminTables &&
//             adminTables.map((item) => {
//               return <Route key={item.table} exact path={"/admin/" + item.table} component={Admin} />;
//             })}
//         </React.Fragment>
//       )}
//       <Redirect from="*" to="/" />
//     </Switch>
//   );
// };

const AppRouter = () => {
  // const { setAdminTables, setUser, user } = React.useContext(GlobalContext);

  // useEffectOnce(() => {
  //   (async () => {
  //     try {
  //       if (window.klaytn) {
  //         await window.klaytn.enable();
  //         const caver = new Caver(window.klaytn);
  //         const contract = new caver.klay.Contract(ABI, process.env.REACT_APP_CONTRACT_ADDRESS);
  //         const _isOwner = await contract.methods.isOwner().call({ from: window.klaytn.selectedAddress });

  //         if (_isOwner) {
  //           setUser(true);
  //           const {
  //             data: { result },
  //           } = await axios.post(process.env.REACT_APP_HOST + "/admin/tables", null, httpHeader);
  //           setAdminTables(result);
  //         } else {
  //           setUser(false);
  //         }
  //       }
  //     } catch (e) {
  //     } finally {
  //     }
  //   })();
  // });

  // return user ? <AdminRoutes /> : <GuestRoutes />;
  return <GuestRoutes />;
};

export default AppRouter;
