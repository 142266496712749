import React from "react";
import { useTranslation } from "react-i18next";

import CheckButton from "resources/Images/Staking/btn-check-on.png";
import UnCheckButton from "resources/Images/Staking/btn-check.png";
import PoolIcon from "resources/Images/Staking/166.png";

import { Box, Typography, useMediaQuery, Grid, CardMedia, IconButton } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

const getMax = (tab) => {
  switch (tab) {
    case "v1": {
      return 1;
    }
    case "v3": {
      return 3;
    }
    case "v5": {
      return 5;
    }
    case "v5-a": {
      return 5;
    }
    case "v10": {
      return 10;
    }
  }
};

const MyAssetContents = ({ tab, balance, open, setOpen, setModalType, myAsset, styleNftIdArr, setStyleNftIdArr }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const mdView = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const lgView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              fontSize: mdView ? 14 : 20,
              fontWeight: "500",
              color: theme.palette.white,
              mr: 1,
            }}
          >
            {t("My Taekwon V Gallery")}
          </Typography>
          <Box display="flex" p={1} sx={{ backgroundColor: theme.palette.stakingNftBox, borderRadius: "12px" }}>
            <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.stakingAmountColor }}>
              {balance}
            </Typography>
          </Box>
        </Box>

        <Box
          className={classes.connectButton}
          px={3}
          onClick={() => {
            setModalType("staking");
            setOpen(!open);
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "500",
              color: getMax(tab) < styleNftIdArr.length ? "red" : theme.palette.white,
              mr: 1,
            }}
          >
            {t("STAKING")}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "500",
              color: getMax(tab) < styleNftIdArr.length ? "red" : theme.palette.white,
            }}
          >{`( ${styleNftIdArr.length} / ${getMax(tab)} )`}</Typography>
        </Box>
      </Box>
      <Grid container mt={3}>
        {myAsset &&
          myAsset.map((item, index) => {
            return (
              <Grid key={"myNft" + index} item lg={2.4} md={3} sm={6} xs={6} p={theme.spacing(1)}>
                {styleNftIdArr && styleNftIdArr.includes(item) ? (
                  <Box
                    className={classes.addNft}
                    component="div"
                    onClick={() => {
                      setStyleNftIdArr(
                        styleNftIdArr.filter((el) => {
                          return el.id !== item.id;
                        })
                      );
                    }}
                    sx={{
                      height: 190,
                      cursor: "pointer",
                      border: `3px solid ${theme.palette.stakingAmountColor}`,
                    }}
                  >
                    <Box
                      className={classes.disableNft}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "600",
                          color: "white",
                          position: "absolute",
                        }}
                      >
                        {item.year}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="video"
                      image={item.image}
                      autoPlay
                      sx={{
                        width: "100%",
                        height: lgView ? 180 : 190,
                        objectFit: "contain",
                        borderRadius: "25px",
                      }}
                    />

                    <Box className={classes.nameTag} sx={{ opacity: 0.8, backgroundColor: theme.palette.black }}></Box>

                    <Box className={classes.nameTag}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "500",
                          color: theme.palette.white,
                          mr: 0.5,
                        }}
                      >
                        {item.title}
                      </Typography>

                      <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white }}>
                        {item.name}
                      </Typography>
                    </Box>

                    <Box className={classes.checkTag}>
                      <IconButton
                        sx={{
                          p: 0,
                          width: 34,
                          height: 34,
                        }}
                      >
                        <CardMedia component="img" image={CheckButton} />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <Box position="relative">
                    {item.isStaking && (
                      <React.Fragment>
                        <Box
                          className={classes.disableNft}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1,
                            backgroundColor: theme.palette.black,
                            opacity: 0.8,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: "600",
                              color: theme.palette.white,
                              position: "absolute",
                            }}
                          >
                            {item.year}
                          </Typography>
                        </Box>
                        <Box className={classes.disableNft} sx={{ zIndex: 2 }}>
                          <IconButton
                            sx={{
                              p: 0,
                              position: "relative",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={PoolIcon}
                              sx={{ width: 50, height: 28, objectFit: "contain" }}
                            />
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: "600",
                                color: theme.palette.white,
                                position: "absolute",
                              }}
                            >
                              {item.stakingType.toUpperCase()}
                            </Typography>
                          </IconButton>
                        </Box>
                      </React.Fragment>
                    )}

                    <Box
                      className={classes.addNft}
                      component="div"
                      onClick={() => {
                        setStyleNftIdArr((styleNftIdArr) => styleNftIdArr.concat(item));
                      }}
                      sx={{
                        height: 190,
                        "&:hover": {
                          WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                          boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Box
                        className={classes.disableNft}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: "600",
                            color: "white",
                            position: "absolute",
                          }}
                        >
                          {item.year}
                        </Typography>
                      </Box>
                      <CardMedia
                        component="video"
                        image={item.image}
                        autoPlay
                        sx={{
                          width: "100%",
                          height: 190,
                          objectFit: "contain",
                          borderRadius: "25px",
                        }}
                      />
                      <Box
                        className={classes.nameTag}
                        sx={{ opacity: 0.8, backgroundColor: theme.palette.black }}
                      ></Box>
                      <Box className={classes.nameTag}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: "500",
                            color: theme.palette.white,
                            mr: 0.5,
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white }}>
                          {item.name}
                        </Typography>
                      </Box>
                      <Box className={classes.checkTag}>
                        <IconButton
                          sx={{
                            p: 0,
                            width: 34,
                            height: 34,
                          }}
                        >
                          <CardMedia component="img" image={UnCheckButton} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    addNft: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.stakingNftBox,
      borderRadius: "25px",
    },
    disableNft: {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "25px",
    },
    connectButton: {
      height: 50,
      background: "linear-gradient(101deg, #9de2f7 -1%, #3858c2 97%)",
      borderRadius: "23px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    nameTag: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: 40,
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingTop: 10,
      paddingBottom: 10,
    },
    checkTag: {
      position: "absolute",
      width: "100%",
      top: 10,
      right: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
  };
});

export default MyAssetContents;
