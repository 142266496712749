import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Box, Select, InputLabel, MenuItem, FormControl, useMediaQuery, TextField, Button, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import moment from "moment";

const AdminGridHeader = (params) => {
  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    searchOnSubmit,
    selectColumn,
    setSelectColumn,
    columns,
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pageOnSubmit,
    selectPages,
    setSelectPages,
  } = params;

  return (
    <Box>
      <Grid container display="flex" justifyContent="space-between" alignItems="flex-end">
        <form onSubmit={searchOnSubmit} className={classes.form}>
          <Box mr={1.5}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Box sx={{ width: smView ? 115 : 150 }}>
                <DatePicker
                  label="시작 날짜"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(moment(value).format("YYYY-MM-DD"));
                  }}
                  inputFormat={"yyyy-MM-dd"}
                  mask={"____-__-__"}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box mr={1.5}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Box sx={{ width: smView ? 115 : 150 }}>
                <DatePicker
                  label="끝 날짜"
                  value={endDate}
                  onChange={(value) => {
                    setEndDate(moment(value).format("YYYY-MM-DD"));
                  }}
                  inputFormat={"yyyy-MM-dd"}
                  mask={"____-__-__"}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box mr={1.5}>
            <Button type="submit" variant="contained">
              {t("검색")}
            </Button>
          </Box>
        </form>
        <form onSubmit={searchOnSubmit} className={classes.form}>
          <Box mr={1.5}>
            <FormControl variant="standard">
              <InputLabel id="label">{t("컬럼")}</InputLabel>
              {columns && (
                <Select
                  id="select"
                  labelId="label"
                  value={selectColumn}
                  defaultValue={selectColumn}
                  label="컬럼"
                  onChange={(e) => setSelectColumn(e.target.value)}
                >
                  {columns.map((item, index) => {
                    return (
                      <MenuItem key={"search" + index} value={item.field}>
                        {item.headerName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Box>

          <Box mr={1.5}>
            <TextField
              variant="standard"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="검색..."
              sx={{ maxWidth: 122 }}
            />
          </Box>

          <Box mr={1.5}>
            <Button type="submit" variant="contained">
              {t("검색")}
            </Button>
          </Box>
        </form>

        <form className={classes.form} onSubmit={pageOnSubmit}>
          <Box mr={1.5}>
            <TextField
              label={t("페이지")}
              variant="standard"
              value={selectPages}
              onChange={(e) => setSelectPages(e.target.value)}
              placeholder="pages..."
            />
          </Box>
          <Box mr={1.5}>
            <Button type="submit" variant="contained">
              {t("이동")}
            </Button>
          </Box>
        </form>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    paper: {},
    form: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginBottom: 30,
    },
  };
});

export default AdminGridHeader;
