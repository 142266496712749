import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CardMedia, Container, Typography, useMediaQuery, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useEffectOnce } from "react-use";

import StakingBackgroundImage from "resources/Images/Staking/bg-staking-main.png";
import StakingLogo from "resources/Images/Staking/img-mining-text.png";
import OpenSeaButton from "resources/Images/Staking/btn-opensea.png";

const ImageLayer = () => {
  const [isConnected, setIsConnected] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const mdView = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffectOnce(() => {
    (async () => {
      try {
        if (window.klaytn) {
          await window.klaytn.enable();
          const address = window.klaytn.selectedAddress;
          if (address) {
            setIsConnected(true);
          } else {
            setIsConnected(false);
          }
        }
      } catch (e) {
      } finally {
      }
    })();
  });

  return (
    <Box position="relative" zIndex={0}>
      <CardMedia component="img" image={StakingBackgroundImage} className={classes.backgroundImage} />
      <Container className={classes.backgroundContainer} sx={{ alignItems: mdView ? "center" : "flex-start" }}>
        <CardMedia component="img" image={StakingLogo} sx={{ width: mdView ? 350 : 500, objectFit: "cover" }} />

        <Box display="flex" mt={6}>
          {isConnected ? (
            <Button sx={{ mr: 3, p: 0 }}>
              <Box className={classes.connectButton}>
                <Typography sx={{ fontSize: 16, fontWeight: "500", color: theme.palette.white }}>
                  {t("CONNECTED")}
                </Typography>
              </Box>
            </Button>
          ) : (
            <Button sx={{ mr: 3, p: 0 }}>
              <Box className={classes.connectButton}>
                <Typography sx={{ fontSize: 16, fontWeight: "500", color: theme.palette.white }}>
                  {t("NEED CONNECT")}
                </Typography>
              </Box>
            </Button>
          )}

          <Button
            sx={{ p: 0 }}
            onClick={() => {
              window.open("https://opensea.io/collection/official-robot-taekwon-v");
            }}
          >
            <CardMedia component="img" image={OpenSeaButton} className={classes.OpenSeaButton} />
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    backgroundImage: {
      objectFit: "cover",
      position: "absolute",
      zIndex: -1,
      height: "90vh",
    },
    backgroundContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "90vh",
    },
    connectButton: {
      width: 220,
      height: 60,
      background: "linear-gradient(102deg, #9de2f7 1%, #3858c2 99%)",
      borderRadius: 34,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        WebKitBoxShadow: `0px 0px 4px 3px ${theme.palette.white}`,
        boxShadow: `0px 0px 4px 3px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    OpenSeaButton: {
      width: 220,
      height: 60,
      objectFit: "cover",
      "&:hover": {
        borderRadius: 34,
        WebKitBoxShadow: `0px 0px 4px 3px ${theme.palette.white}`,
        boxShadow: `0px 0px 4px 3px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
  };
});

export default ImageLayer;
