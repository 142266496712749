import React from "react";
import Caver from "caver-js";
import numeral from "numeral";
import ReactExport from "react-export-excel";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Slider,
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  TextField,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Page from "components/Page";
import HomeHeader from "components/Home/HomeHeader";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";

import ABI from "resources/abi/v_abi";
import RV from "resources/rv.png";
import { runContract } from "utils";
import useInput from "hooks/useInput";
import Loader from "components/Loader";
import GlobalContext from "context/GlobalContext";
import axios from "axios";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Wallet = ({ history }) => {
  const [viewData, setViewData] = React.useState(null);
  const [nfts, setNfts] = React.useState([]);
  const [isOwner, setIsOwner] = React.useState(false);
  const [snapdata, setSnapdata] = React.useState(null);
  const [snapLoading, setSnapLoading] = React.useState(null);
  const [isSnapshot, setIsSnapshot] = React.useState(false);
  const { walletVerification, network } = React.useContext(GlobalContext);
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [callResult, setCallResult] = React.useState({});

  // isWhitelist1
  const isWhitelist1_account = useInput("");

  // isWhitelist2
  const isWhitelist2_account = useInput("");

  // mintVForAdmin
  const mintVForAdmin_requestCount = useInput("");
  const mintVForAdmin_requestAddress = useInput("");

  // setWhitelist1
  const setWhitelist1_requestAddress = useInput("");
  const setWhitelist1_requestCount = useInput("");

  // pushWhitelist2
  const pushWhitelist2_requestAddress = useInput("");

  // popWhitelist2
  const popWhitelist2_requestAddress = useInput("");

  // setupPrice
  const setupPrice_defaultMintPrice = useInput("");
  const setupPrice_publicMintPrice = useInput("");

  // pause

  // unpause

  // transferOwnership
  const transferOwnership_newOwner = useInput("");

  // setupConfig
  const setupConfig_fixedLimit = useInput("");
  const setupConfig_fixedStartBlock = useInput("");
  const setupConfig_fixedEndBlock = useInput("");
  const setupConfig_unFixedLimit = useInput("");
  const setupConfig_unFixedTxLimit = useInput("");
  const setupConfig_unFixedStartBlock = useInput("");
  const setupConfig_unFixedEndBlock = useInput("");
  const setupConfig_publicLimit = useInput("");
  const setupConfig_publicTxLimit = useInput("");
  const setupConfig_publicMintPrice = useInput("");
  const setupConfig_publicStartBlock = useInput("");
  const setupConfig_startBlock = useInput("");

  const modifier = async (method) => {
    switch (method) {
      case "setWhitelist1": {
        break;
      }
      case "pushWhitelist2": {
        const isWhitelist2 = await contractCall({ method: "isWhitelist2", input: [pushWhitelist2_requestAddress] });
        if (isWhitelist2) {
          return "이미 비확정 화이트리스트에 속한 지갑주소입니다.";
        }
        break;
      }
      case "popWhitelist2": {
        const isWhitelist2 = await contractCall({ method: "isWhitelist2", input: [popWhitelist2_requestAddress] });
        if (!isWhitelist2) {
          return "해당 지갑주소는 비확정 화이트리스트가 아닙니다.";
        }
        break;
      }
      case "setupPrice": {
        break;
      }
      case "pause": {
        const isPaused = await contractCall({ method: "isPaused" });
        if (isPaused) {
          return "이미 일시정지 상태입니다.";
        }
        break;
      }
      case "unpause": {
        const isPaused = await contractCall({ method: "isPaused" });
        if (!isPaused) {
          return "현재 일시정지 상태가 아닙니다.";
        }
        break;
      }
      case "transferOwnership": {
        break;
      }
      case "setupConfig": {
        break;
      }
      default: {
        return true;
      }
    }

    return true;
  };

  const contractCall = async ({ type = "call", method, input = [] }) => {
    const caver = new Caver(window.klaytn);
    const mapInput =
      typeof input === "object"
        ? input.map((item) => {
            return item.value;
          })
        : input;
    const run = await runContract({
      abi: ABI,
      caver,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
      type,
      from: window.klaytn.selectedAddress,
      method,
      input: mapInput,
    });
    if (run.result === "error") {
      return enqueueSnackbar("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요.", { variant: "error" });
    }

    if (type === "call") {
      const json = { ...callResult };
      json[method] = run.result;
      setCallResult(json);
      return run.result;
    }
    const condition = await modifier(method);
    if (typeof condition === "boolean" && condition) {
      const transactionParameters = {
        from: window.klaytn.selectedAddress, // must match user's active address.
        data: run.result.deploy,
      };
      transactionParameters["to"] = process.env.REACT_APP_CONTRACT_ADDRESS;
      try {
        const txHash = await window.klaytn.sendAsync({
          method: "klay_sendTransaction",
          params: [transactionParameters],
        });
      } catch (e) {
        enqueueSnackbar("컨트랙트 콜에 실패하였습니다. 올바른 입력값을 입력해주세요.");
      }
    } else {
      enqueueSnackbar(condition, { variant: "error" });
    }
  };

  const onSanpShot = async () => {
    const getConfig = await contractCall({ method: "getConfig" });
    const snapshot = [];

    for (let i = 0; i < getConfig[12]; i++) {
      const address = await contractCall({ method: "ownerOf", input: i });
      const tokenURI = await contractCall({ method: "tokenURI", input: i.toString() });

      const { data } = await axios.get(tokenURI);

      snapshot.push({ token_id: i, address, name: data.name });
      setSnapLoading({ min: 0, now: i, max: getConfig[12] });
    }

    setSnapdata(snapshot);
  };

  const resetSnap = () => {
    setSnapLoading(null);
    setSnapdata(null);
  };

  useEffectOnce(() => {
    (async () => {
      await walletVerification();
      if (window.klaytn.networkVersion !== Number(process.env.REACT_APP_MAINCHAIN)) {
        return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
      }
      if (window.klaytn) {
        const caver = new Caver(window.klaytn);
        const balance = await caver.klay.getBalance(window.klaytn.selectedAddress);
        setViewData({ walletAddress: window.klaytn.selectedAddress, balance: caver.utils.fromWei(balance.toString()) });
        const contract = new caver.klay.Contract(ABI, process.env.REACT_APP_CONTRACT_ADDRESS);
        const _tokensOfOwner = await contract.methods.tokensOfOwner(window.klaytn.selectedAddress).call();
        setNfts(_tokensOfOwner);
        const _isOwner = await contract.methods.isOwner().call({ from: window.klaytn.selectedAddress });
        setIsOwner(_isOwner);
        if (_isOwner) {
          const getConfig = await contractCall({ method: "getConfig" });
          const getPrice = await contractCall({ method: "getPrice" });

          setupConfig_fixedLimit.setValue(getConfig[0]);
          setupConfig_fixedStartBlock.setValue(getConfig[1]);
          setupConfig_fixedEndBlock.setValue(getConfig[2]);
          setupConfig_unFixedLimit.setValue(getConfig[3]);
          setupConfig_unFixedTxLimit.setValue(getConfig[4]);
          setupConfig_unFixedStartBlock.setValue(getConfig[5]);
          setupConfig_unFixedEndBlock.setValue(getConfig[6]);
          setupConfig_publicLimit.setValue(getConfig[7]);
          setupConfig_publicTxLimit.setValue(getConfig[8]);
          setupConfig_publicMintPrice.setValue(getConfig[9]);
          setupConfig_publicStartBlock.setValue(getConfig[10]);
          setupConfig_startBlock.setValue(getConfig[11]);

          setupPrice_defaultMintPrice.setValue(getPrice[0]);
          setupPrice_publicMintPrice.setValue(getPrice[1]);
        }
      }
    })();
  });

  return (
    <Page title={"Wallet"}>
      <HomeHeader />
      <Paper className={classes.root}>
        <Container maxWidth="sm">
          <Paper className={classes.body}>
            {viewData ? (
              <React.Fragment>
                <Box mb={1}>
                  <Typography sx={{ color: theme.palette.aqua, fontSize: 30, fontWeight: "900" }}>
                    {t("My Wallet")}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ color: theme.palette.white, fontSize: 15, fontWeight: "700", opacity: 0.7 }}>
                    {t("현재 연동중인 지갑의 정보를 확인합니다.")}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Box mb={1.5}>
                  <Typography className={classes.infoTitle}>{t("Wallet Address")}</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ color: theme.palette.white, fontSize: smView ? 12 : 15, opacity: 0.7 }}>
                      {viewData.walletAddress.toLowerCase()}
                    </Typography>

                    <CopyToClipboard
                      onCopy={() => enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "info" })}
                      text={viewData.walletAddress.toLowerCase()}
                    >
                      <IconButton>
                        <ContentCopyIcon className={classes.copyIcon} sx={{ fontSize: smView ? 15 : 22 }} />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                </Box>
                <Box mb={1.5}>
                  <Typography className={classes.infoTitle} mb={0.5}>
                    {t("Balance")}
                  </Typography>
                  <Typography sx={{ color: theme.palette.white, fontSize: 15, opacity: 0.7 }}>
                    {numeral(viewData.balance).format("0,0.[0000]") + " KLAY"}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                {isOwner && (
                  <React.Fragment>
                    <Typography sx={{ color: theme.palette.aqua, fontSize: 30, fontWeight: "900", mb: 2 }}>
                      {t("어드민 상태조회")}
                    </Typography>
                    {[
                      {
                        title: t("확정 화이트리스트 여부"),
                        method: "isWhitelist1",
                        input: [isWhitelist1_account],
                      },
                    ].map((item, index) => {
                      const filtered = ABI.filter((method) => {
                        return method.name === item.method;
                      });
                      const [method] =
                        filtered &&
                        filtered.filter((item) => {
                          return item.inputs.length > 0;
                        });

                      return (
                        <Box sx={{ mb: 1 }} key={"top" + index}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography className={classes.infoTitle}>{item.title}</Typography>
                          </Box>
                          <Box
                            sx={{ pt: 1 }}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="end"
                          >
                            {method.inputs &&
                              method.inputs.map((input, index) => {
                                return (
                                  <TextField
                                    label={input.name}
                                    value={item.input[index].value}
                                    onChange={item.input[index].onChange}
                                    key={"top_input" + index}
                                    fullWidth
                                    placeholder={input.name}
                                    sx={{ mb: 1 }}
                                    variant="outlined"
                                    size="small"
                                  />
                                );
                              })}
                            <Button
                              onClick={contractCall.bind(null, {
                                input: item.input,
                                method: item.method,
                                type: "call",
                              })}
                              variant="contained"
                            >
                              {t("컨트랙트 콜") + ` (${method.name})`}
                            </Button>
                            <Typography sx={{ color: "white" }}>
                              {callResult[item.method] !== undefined && callResult[item.method].toString()}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}

                    <Divider className={classes.divider} />
                    <Typography sx={{ color: theme.palette.aqua, fontSize: 30, fontWeight: "900", mb: 2 }}>
                      {t("어드민 기능")}
                    </Typography>
                    {[
                      {
                        title: t("어드민 민팅"),
                        method: "mintVForAdmin",
                        input: [mintVForAdmin_requestCount, mintVForAdmin_requestAddress],
                      },

                      {
                        title: t("가격 설정"),
                        method: "setupPrice",
                        input: [setupPrice_defaultMintPrice, setupPrice_publicMintPrice],
                      },
                      {
                        title: t("일시중지"),
                        method: "pause",
                      },
                      {
                        title: t("재개"),
                        method: "unpause",
                      },
                      {
                        title: t("어드민지갑으로 모든 클레이 출금"),
                        method: "withdraw",
                      },
                      {
                        title: t("어드민 지갑 변경"),
                        method: "transferOwnership",
                        input: [transferOwnership_newOwner],
                      },
                      {
                        title: t("설정 변경"),
                        method: "setupConfig",
                        input: [
                          setupConfig_fixedLimit,
                          setupConfig_fixedStartBlock,
                          setupConfig_fixedEndBlock,
                          setupConfig_unFixedLimit,
                          setupConfig_unFixedTxLimit,
                          setupConfig_unFixedStartBlock,
                          setupConfig_unFixedEndBlock,
                          setupConfig_publicLimit,
                          setupConfig_publicTxLimit,
                          setupConfig_publicMintPrice,
                          setupConfig_publicStartBlock,
                          setupConfig_startBlock,
                        ],
                      },
                    ].map((item, index) => {
                      const [method] = ABI.filter((method) => {
                        return method.name === item.method;
                      });
                      return (
                        <Box sx={{ mb: 1 }} key={"top" + index}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography className={classes.infoTitle}>{item.title}</Typography>
                          </Box>
                          <Box
                            sx={{ pt: 1 }}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="end"
                          >
                            {method.inputs &&
                              method.inputs.map((input, index) => {
                                return (
                                  <TextField
                                    label={input.name}
                                    value={item.input[index].value}
                                    onChange={item.input[index].onChange}
                                    key={"top_input" + index}
                                    fullWidth
                                    placeholder={input.name}
                                    sx={{ mb: 1 }}
                                    variant="outlined"
                                    size="small"
                                  />
                                );
                              })}
                            <Button
                              onClick={contractCall.bind(null, {
                                input: item.input,
                                method: item.method,
                                type: "deploy",
                              })}
                              variant="contained"
                            >
                              {t("컨트랙트 콜") + ` (${method.name})`}
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}

                    <Divider className={classes.divider} />
                  </React.Fragment>
                )}
                {isSnapshot && (
                  <React.Fragment>
                    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                      <Button disabled={snapLoading} onClick={onSanpShot} variant="contained">
                        {t("스냅샷 데이터 생성")}
                      </Button>
                      <ExcelFile
                        element={
                          <Button onClick={resetSnap} disabled={!snapdata} variant="contained">
                            {t("스냅샷 데이터 저장")}
                          </Button>
                        }
                      >
                        <ExcelSheet data={snapdata} name="SNAPSHOT">
                          <ExcelColumn label="token_id" value="token_id" />
                          <ExcelColumn label="address" value="address" />
                          <ExcelColumn label="name" value="name" />
                        </ExcelSheet>
                      </ExcelFile>
                    </Box>
                    <Box>
                      {snapLoading && (
                        <Slider
                          aria-label="Custom marks"
                          value={snapLoading.now}
                          min={snapLoading.min}
                          max={snapLoading.max}
                          // step={10}
                          valueLabelDisplay="auto"
                          marks={[
                            {
                              value: snapLoading.min,
                              label: snapLoading.min,
                            },
                            {
                              value: snapLoading.max / 2,
                              label: `${snapLoading.now}/${snapLoading.max}`,
                            },
                            {
                              value: snapLoading.max,
                              label: snapLoading.max,
                            },
                          ]}
                          // disableSwap={true}
                        />
                      )}
                    </Box>
                    <Divider className={classes.divider} />
                  </React.Fragment>
                )}
                {!isOwner && (
                  <React.Fragment>
                    <Typography sx={{ color: theme.palette.aqua, fontSize: 30, fontWeight: "900", mb: 2 }}>
                      {t("NFTs")}
                    </Typography>
                    <ImageList cols={3} rowHeight={164} gap={5}>
                      {nfts.length === 0
                        ? "Null"
                        : nfts.map((item) => (
                            <ImageListItem
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                window.open(`
                          https://opensea.io/assets/klaytn/${process.env.REACT_APP_CONTRACT_ADDRESS}/${item}
                          `);
                              }}
                              key={item}
                            >
                              <img
                                src={RV}
                                // srcSet={`https://picsum.photos/id/${item}/164/164?&blur=2`}
                                alt={item}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ))}
                    </ImageList>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <Loader></Loader>
            )}
          </Paper>
        </Container>
        <Box
          onClick={() => setIsSnapshot(true)}
          sx={{ bottom: 0, right: 0, width: 25, height: 25, position: "absolute" }}
        ></Box>
      </Paper>
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    minHeight: "100%",
    backgroundColor: theme.palette.black,
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    backgroundColor: theme.palette.darkGreen,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: theme.palette.black,
    opacity: 0.4,
  },
  infoTitle: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: "900",
  },
  copyIcon: {
    color: theme.palette.white,
    marginBottom: 3,
  },
}));

export default Wallet;
