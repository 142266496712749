import numeral from "numeral";
import Caver from "caver-js";

export const httpHeader = () => ({
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  },
});

export const networkRequire = async (network) => {
  // if (network !== process.env.REACT_APP_CHAIN_ID_HEX) {
  //   return true;
  //   return await window.ethereum.request({
  //     method: "wallet_addEthereumChain",
  //     params: [
  //       {
  //         // chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
  //         chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
  //         chainName: process.env.REACT_APP_COIN_NAME,
  //         nativeCurrency: {
  //           name: process.env.REACT_APP_COIN_NAME + " Mainnet",
  //           symbol: process.env.REACT_APP_COIN_TICKER.toUpperCase(), // 2-6 characters long
  //           decimals: 18,
  //         },
  //         // rpcUrls: [process.env.REACT_APP_HTTP_HOST],
  //         rpcUrls: [process.env.REACT_APP_HTTP_HOST],
  //         blockExplorerUrls: [process.env.REACT_APP_EXPLORER_HOST],
  //         iconUrls: [process.env.REACT_APP_SYMBOL_PATH],
  //       },
  //     ],
  //   });
  // } else {
  //   return true;
  // }
};

export const errorFactory = (code, action = () => null) => {
  try {
    switch (code) {
      case "duplicate_email": {
        return "중복된 이메일입니다.";
      }
      case "invalid_recommend": {
        return "올바르지 않은 추천인입니다.";
      }
      case "invalid_user":
        return "올바르지 않은 이메일 혹은 비밀번호입니다.";
      case "invalid_token":
        return "올바르지 않은 인증번호입니다.";
      case "email_timeout":
        return "인증번호 유효기간이 지났습니다. 인증번호를 재전송 후 다시 진행해주세요.";
      default:
        return "잠시 후에 시도해 주세요.";
    }
  } catch {
  } finally {
    action();
  }
};

export const isFloat = (value) => {
  if (value === 0) {
    return value;
  }
  if (!value) {
    return "-";
  }
  if (!isNaN(Number(value)) && value.toString().substring(0, 2) !== "0x") {
    if (value % 1 === 0) {
      return numeral(value).format("0");
    } else {
      return numeral(value).format("0,0.0000");
    }
  } else {
    return value;
  }
};

export const runContract = async (props) => {
  const { method, input, abi, contractAddress, type, from, caver, value = 0 } = props;
  if (!contractAddress) {
    return {
      result: { limit: 21000, deploy: null, value: caver.utils.toHex(input[1].toString()) },
    };
  }

  if (!abi.some((item) => item.name === method)) {
    return;
  }

  const contract = new caver.klay.Contract(abi, contractAddress);
  try {
    switch (type) {
      case "call": {
        if (typeof input === "object") {
          const call = await contract.methods[method](...input).call({ from });
          return { result: call };
        } else {
          const call = await contract.methods[method](input).call({ from });
          return { result: call };
        }
      }
      case "deploy": {
        const deploy = await contract.methods[method](...input).encodeABI({ from, value });
        return {
          result: {
            deploy,
            value: null,
          },
        };
      }
      default: {
        throw Error();
      }
    }
  } catch (e) {
    console.log(e);
    return {
      result: "error",
    };
  }
};

export const runContractETH = async (props) => {
  const { method, input, abi, contractAddress, type, from, web3, value = 0 } = props;
  if (!contractAddress) {
    return {
      result: { limit: 21000, deploy: null, value: web3.utils.toHex(input[1].toString()) },
    };
  }

  if (!abi.some((item) => item.name === method)) {
    return;
  }

  const contract = new web3.eth.Contract(abi, contractAddress);
  try {
    switch (type) {
      case "call": {
        if (typeof input === "object") {
          const call = await contract.methods[method](...input).call({ from });
          return { result: call };
        } else {
          const call = await contract.methods[method](input).call({ from });
          return { result: call };
        }
      }
      case "deploy": {
        const deploy = await contract.methods[method](...input).encodeABI({ from, value });
        return {
          result: {
            deploy,
            value: null,
          },
        };
      }
      default: {
        throw Error();
      }
    }
  } catch (e) {
    console.log(e);
    return {
      result: "error",
    };
  }
};
