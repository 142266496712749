import { createTheme } from "@mui/material/styles";

import typography from "styles/theme/typography";
import shadows from "styles/theme/shadows";
// import customColors from "styles/theme/colors";
import { colors } from "@mui/material";

const theme = createTheme({
  palette: {
    // mode: "dark",
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: colors.indigo[600],
    },
    secondary: {
      main: colors.indigo[300],
    },
    darkGrey: "#999",
    lightGrey: "#e6e6e6",
    blue: "#005CEE",
    white: "#fff",
    black: "#000000",
    red: "#ef1a22",
    aqua: "#5affee",
    sky: "#3bd7ff",
    lightSky: "#c8fff9",
    borderGrey: "#707070",
    darkBlue: "#092030",
    deepDarkBlue: "#14182d",
    darkGreen: "#0a5162",
    lightBlack: "#222b31",
    deepBlue: "#171c34",
    lightYellow: "#ffda91",
    orange: "#ffaa00",
    lightBlue: "#5e6db4",
    indigo: "#071321",
    black2: "#03070c",
    textShadow: "rgba(239, 26, 34, 0.43)",
    boxShadow: "rgba(239, 26, 34, 0.31)",
    lightPupple: "#8a87ff",
    darkPupple: "#1b195d",
    borderPupple: "#8886dc",
    mintPupple: "#b4b2ff",
    slider: "#060041",
    defaultColor: "#20202a",
    stakingAmountColor: "#9de2f7",
    stakingContentsBox: "#23272e",
    stakingBorder: "#697488",
    stakingNftBox: "#31353d",
    popOverBackground: "#14171d",
    stakingModal: "#272b36",
    stakingModalText: "#abb3c1",
  },
  typography,
  shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          userSelect: "none",
          WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
          WebkitTouchCallout: "none",
        },
        input: {
          userSelect: "text",
        },
        html: {
          backgroundColor: "black",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 280,
      },
    },
  },
});

export default theme;
