import React from "react";
import Page from "components/Page";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import Caver from "caver-js";
import Backdrop from "@mui/material/Backdrop";
import "../Home/assets/css/common.css";
import "../Home/assets/css/style.css";

import HomeHeader from "components/Home/HomeHeader";
import StakingModal from "pages/Staking/Modal";
import ImageLayer from "pages/Staking/ImageLayer";
import TabSection from "pages/Staking/TabSection";
import MyAssetContents from "pages/Staking/MyAssetContents";
import StakingAssetContents from "pages/Staking/StakingAssetContents";
import NoticeIcon from "resources/Images/Staking/ic-noti.png";
import { runContract } from "utils";
import VABI from "resources/abi/v_abi";

import {
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
  Grid,
  CardMedia,
  Popover,
  CircularProgress,
  CircularProgressW,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import GlobalContext from "context/GlobalContext";
import Footer from "components/Home/Footer";

const Staking = ({ history }) => {
  const [windowW, setWindowW] = React.useState("");
  const [isConnected, setIsConnected] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [modalType, setModalType] = React.useState(null);
  const [tab, setTab] = React.useState("v1");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [styleNftId, setStyleNftId] = React.useState(null);
  const [styleNftIdArr, setStyleNftIdArr] = React.useState([]);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [loadingPercent, setLoadingPercent] = React.useState(false);
  const { walletVerification } = React.useContext(GlobalContext);

  const [focusedGroup, setFocusedGroup] = React.useState(null);

  const [myAsset, setMyAsset] = React.useState(null);
  const [balance, setBalance] = React.useState(null);

  const [stakingMoreAsset, setStakingMoreAsset] = React.useState([]);
  const [dashboard, setDashboard] = React.useState(null);
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const mdView = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const addButtonRef = React.useRef("");
  const popOverIsOpen = Boolean(anchorEl);
  const popOverId = popOverIsOpen ? "simple-popover" : undefined;
  const initialize = async () => {
    if (window.klaytn.networkVersion !== Number(process.env.REACT_APP_MAINCHAIN)) {
      return enqueueSnackbar(t("카이카스 네트워크를 클레이튼 메인넷으로 설정해주세요."), { variant: "error" });
    }
    try {
      setAnchorEl(null);
      setStyleNftIdArr([]);
      setStyleNftId(null);
      setOpen(false);
      setModalType(null);

      if (window.klaytn) {
        setBackdropOpen(true);
        await window.klaytn.enable();
        const address = window.klaytn.selectedAddress;
        if (address) {
          setIsConnected(true);

          const caver = new Caver(window.klaytn);
          const { result: balanceOf } = await runContract({
            abi: VABI,
            contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
            type: "call",
            from: address,
            caver,
            method: "balanceOf",
            input: [address],
          });
          setBalance(balanceOf);
          const mylist = [];

          setLoadingPercent({ now: 0, max: balanceOf });

          for (let i = 0; i < balanceOf; i++) {
            const { result: tokenOfOwnerByIndex } = await runContract({
              abi: VABI,
              contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
              type: "call",
              from: address,
              caver,
              method: "tokenOfOwnerByIndex",
              input: [address, i],
            });
            const { result: tokenURI } = await runContract({
              abi: VABI,
              contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
              type: "call",
              from: address,
              caver,
              method: "tokenURI",
              input: [tokenOfOwnerByIndex],
            });
            setLoadingPercent((item) => ({ ...item, now: i }));
            const { data } = await axios.get(tokenURI);

            mylist.push({
              ...data,
              innerName: data.name,
              year: data.attributes[0].value,
              tokenId: tokenOfOwnerByIndex,
              id: tokenOfOwnerByIndex,
            });
          }

          const { data: dashboard } = await axios.post(`${process.env.REACT_APP_HOST}/staking/dashboard`, {
            walletAddress: address,
            stakingList: mylist,
            tab,
          });
          setDashboard(dashboard);

          const { data: sortedData } = await axios.post(`${process.env.REACT_APP_HOST}/staking/ing`, {
            stakingList: mylist,
            walletAddress: address,
          });

          if (!sortedData.status) {
            throw Error("sorted_data_error");
          }

          const resultList = mylist.map((item) => {
            const filtered = sortedData.duplicated.filter((filteredItem) => {
              return Number(item.tokenId) === Number(filteredItem.token_id);
            });
            if (filtered.length > 0) {
              return { ...item, isStaking: true, groupId: filtered[0].group_id, stakingType: filtered[0].staking_type };
            } else {
              return { ...item, isStaking: false, groupId: null, stakingType: null };
            }
          });
          setMyAsset(resultList);
        } else {
          setIsConnected(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPercent(null);
      setBackdropOpen(false);
    }
  };

  const registerStaking = async () => {
    const body = {
      stakingType: tab,
      stakingList: styleNftIdArr,
      walletAddress: window.klaytn.selectedAddress,
    };
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/staking/register", body);
    if (data.status) {
      initialize();
      enqueueSnackbar(t("선택하신 NFT가 스테이킹 되었습니다."), { variant: "success" });
    } else {
      enqueueSnackbar(t(data.reason), { variant: "error" });
    }
  };

  const harvestStaking = async ({ action, terminate = false }) => {
    try {
      const body = {
        walletAddress: window.klaytn.selectedAddress,
        group_id: focusedGroup,
        stakingType: tab,
        action,
        terminate,
      };
      setBackdropOpen(true);
      const { data } = await axios.post(process.env.REACT_APP_HOST + "/staking/harvest", body);

      if (data.status) {
        if (terminate) {
          enqueueSnackbar(t("남은 보상 토큰을 전송요청하고 스테이킹을 해제하였습니다." + ` [${data.result.reward}] `), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("스테이킹 보상 토큰 전송을 요청하였습니다." + ` [${data.result.reward}] `), {
            variant: "success",
          });
        }
        initialize();
      } else {
        let text = "";
        switch (data.reason) {
          case "transfer_count_mismatch": {
            text = t(
              "스테이킹한 NFT중 특정 Token ID가 중간에 이동된것을 확인하였습니다. 스테이킹이 자동으로 해제되며, 관련 보상은 수령이 불가합니다."
            );
            break;
          }
          case "transfer_count_mismatch__type": {
            text = t(
              "스테이킹한 NFT중 특정 Token ID가 중간에 이동된것을 확인하였습니다. 확인된 스테이킹은 모두 스테이킹이 자동으로 해제되며, 관련 보상은 수령이 불가합니다."
            );
            break;
          }
          case "less_than_24_hours": {
            text = t("스테이킹 혹은 최종 보상 지급 후 24시간이 경과하지 않았습니다.");
            break;
          }
          case "there_is_no_available_staking": {
            text = t("보상 지급이 가능한 스테이킹이 없습니다.");
            break;
          }
          case "Type is disabled": {
            text = t(
              "개별 스테이킹 신청이 모두 종료되어 상단의 모두 수령하기(Get all Tokens) 버튼을 이용하여 수령해주세요."
            );
            break;
          }
          default: {
            return;
          }
        }
        enqueueSnackbar(t(text + " " + data.reason + " " + (data.result ? JSON.stringify(data.result) : "")), {
          variant: "error",
          autoHideDuration: 10000,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      initialize();
      setBackdropOpen(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (myAsset) {
        setBackdropOpen(true);
        const { data: dashboard } = await axios.post(`${process.env.REACT_APP_HOST}/staking/dashboard`, {
          tab,
          walletAddress: window.klaytn.selectedAddress,
          stakingList: myAsset,
        });
        setDashboard(dashboard);
        setTimeout(() => {
          setBackdropOpen(false);
        }, 500);
      }
    })();
  }, [tab]);

  useEffectOnce(() => {
    (async () => {
      await walletVerification();
      initialize();
      window.klaytn.on("accountsChanged", function ([accounts]) {
        initialize();
      });
    })();
  });

  return (
    <Page title="staking">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={backdropOpen}
        // onClick={handleBackdropClose}
      >
        {loadingPercent ? (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              value={parseInt((100 / loadingPercent.max) * loadingPercent.now)}
              color="inherit"
              variant="determinate"
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="caption" component="div" color="white">
                {parseInt((100 / loadingPercent.max) * loadingPercent.now)}%
              </Typography>
            </Box>
          </Box>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
      <header>
        <div className="header pc_only">
          <h1>
            <div onClick={() => history.push("/")}>
              <img src={require("../Home/assets/img/gnb_logo.png").default} alt="" />
            </div>
          </h1>

          <nav className="nav">
            <ul className="gnb">
              <li>
                <div onClick={() => history.push("/")}>
                  <a>STORY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>HISTORY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>ROADMAP</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>MINING</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>GALLERY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>TEAM</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>PARTNERS</a>
                </div>
              </li>
              <li className="ready">
                <div onClick={() => enqueueSnackbar("곧 공개됩니다!", { variant: "info" })}>
                  <a>GOVERNANCE</a>
                </div>
                <div className="tooltip">
                  <p>곧 공개됩니다!</p>
                </div>
              </li>
            </ul>
          </nav>

          <div className="util_menu">
            <ul>
              <li>
                <div style={{ cursor: "pointer" }} onClick={() => window.open("https://discord.gg/tHfY4cbUSM")}>
                  <img src={require("../Home/assets/img/btn_discord.png").default} alt="디스코드" />
                </div>
              </li>
              <li>
                <div style={{ cursor: "pointer" }} onClick={() => window.open("https://twitter.com/RTKV_nft")}>
                  <img src={require("../Home/assets/img/btn_twitter.png").default} alt="트위터" />
                </div>
              </li>
            </ul>
            {/* <button type="button" onClick={() => history.push("/staking")}>
                <p>CONNECT ON</p>
              </button> */}
          </div>
        </div>

        <div className="header mo_only">
          <div className="moHeader">
            <h1>
              <div onClick={() => history.push("/")}>
                <img src={require("../Home/assets/img/gnb_logo.png").default} alt="" />
              </div>
            </h1>

            <div onClick={() => history.push("/")} className="btnMenu">
              <img src={require("../Home/assets/img/ico_menu.png").default} alt="" />
            </div>
          </div>
          <div className="moMenu">
            <ul className="gnb">
              <li>
                <div onClick={() => history.push("/")}>
                  <a>STORY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>HISTORY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>ROADMAP</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>MINING</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>GALLERY</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>TEAM</a>
                </div>
              </li>
              <li>
                <div onClick={() => history.push("/")}>
                  <a>PARTNERS</a>
                </div>
              </li>
              <li className="ready">
                <div onClick={() => history.push("/")}>
                  <a>GOVERNANCE</a>
                </div>
              </li>
            </ul>
            <ul className="util">
              <li>
                <div style={{ cursor: "pointer" }} onClick={() => window.open("https://discord.gg/tHfY4cbUSM")}>
                  <img src={require("../Home/assets/img/btn_discord.png").default} alt="디스코드" />
                </div>
              </li>
              <li>
                <div style={{ cursor: "pointer" }} onClick={() => window.open("https://twitter.com/RTKV_nft")}>
                  <img src={require("../Home/assets/img/btn_twitter.png").default} alt="트위터" />
                </div>
              </li>
            </ul>
          </div>
          <div className="tooltip">
            <p>곧 공개됩니다!</p>
          </div>
          <div className="dimd"></div>
        </div>
      </header>

      <StakingModal
        setFocusedGroup={setFocusedGroup}
        harvestStaking={harvestStaking}
        registerStaking={registerStaking}
        balance={balance}
        open={open}
        setOpen={setOpen}
        modalType={modalType}
        setModalType={setModalType}
        tab={tab}
        stakingMoreAsset={stakingMoreAsset}
      />
      <Box className={classes.root} pt={1} pb={15}>
        <ImageLayer />
        <Container className={classes.stakingContainer} sx={{ mt: 15 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography sx={{ fontSize: 30, fontWeight: "600", color: theme.palette.white }}>{t("Staking")}</Typography>
            <Box className={classes.totalTokenBox} py={1} pl={mdView ? 2 : 3} pr={1}>
              {dashboard ? (
                <React.Fragment>
                  <Typography sx={{ fontSize: mdView ? 13 : 16, fontWeight: "500", color: theme.palette.white, mr: 3 }}>
                    {t("Total V-Token")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mdView ? 16 : 18,
                      fontWeight: "600",
                      color: theme.palette.stakingAmountColor,
                      mr: 2,
                    }}
                  >
                    {Number(dashboard.groupRewardAll).toFixed(4)}
                  </Typography>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      setModalType("rewardTotal");
                      setOpen(!open);
                    }}
                  >
                    <Box className={classes.getAllTokenButton} py={1} px={2}>
                      <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.black }}>
                        {t("Get all Tokens")}
                      </Typography>
                    </Box>
                  </Button>
                </React.Fragment>
              ) : (
                <Box sx={{ width: 283, height: 49, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress size={15} />
                </Box>
              )}
            </Box>
          </Box>

          <Box className={classes.stakingContentsBox} p={mdView ? 1.5 : 3} mt={3}>
            <Box>
              <Box width="100%" ref={addButtonRef}>
                <TabSection tab={tab} setTab={setTab} />
              </Box>

              {isConnected ? (
                <React.Fragment>
                  {myAsset && myAsset.length === 0 && (
                    <Box className={classes.noMyAssetBox}>
                      <CardMedia component="img" image={NoticeIcon} sx={{ width: 34, height: 34 }} />
                      <Typography sx={{ mt: 2.5, fontSize: 21, fontWeight: "500", color: theme.palette.stakingBorder }}>
                        {t("You don't have Taekwon V NFTs")}
                      </Typography>
                    </Box>
                  )}
                  {myAsset === null && (
                    <Box className={classes.noMyAssetBox}>
                      <CircularProgress />
                    </Box>
                  )}
                  {myAsset && balance && (
                    <Grid container mt={4}>
                      <Grid item lg={2.4} md={3} sm={6} xs={6} p={theme.spacing(1)}>
                        <Box className={classes.addButtonBox} onClick={(e) => setAnchorEl(e.currentTarget)}>
                          <Typography sx={{ fontSize: 40, fontWeight: "500", color: "inherit" }}>{"+"}</Typography>
                        </Box>

                        <Popover
                          id={popOverId}
                          open={popOverIsOpen}
                          anchorEl={anchorEl}
                          onClose={() => {
                            setAnchorEl(null);
                            setStyleNftIdArr([]);
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          PaperProps={{
                            style: {
                              marginTop: 20,
                              borderRadius: "10px",
                              width: mdView ? "100%" : addButtonRef.current.offsetWidth - 10,
                              backgroundColor: theme.palette.popOverBackground,
                              height: 650,
                              overflow: "auto",
                              padding: 30,
                              paddingLeft: mdView ? 10 : 30,
                              paddingRight: mdView ? 10 : 30,
                              display: "flex",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <MyAssetContents
                            tab={tab}
                            balance={balance}
                            open={open}
                            setOpen={setOpen}
                            setModalType={setModalType}
                            myAsset={myAsset}
                            styleNftIdArr={styleNftIdArr}
                            setStyleNftIdArr={setStyleNftIdArr}
                          />
                        </Popover>
                      </Grid>

                      <StakingAssetContents
                        tab={tab}
                        setFocusedGroup={setFocusedGroup}
                        open={open}
                        setOpen={setOpen}
                        setModalType={setModalType}
                        stakingAsset={dashboard.myStakingGroup}
                        styleNftId={styleNftId}
                        setStyleNftId={setStyleNftId}
                        setStakingMoreAsset={setStakingMoreAsset}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ) : (
                <Box className={classes.noMyAssetBox}>
                  <CardMedia component="img" image={NoticeIcon} sx={{ width: 34, height: 34 }} />
                  <Typography sx={{ mt: 2.5, fontSize: 21, fontWeight: "500", color: theme.palette.stakingBorder }}>
                    {t("Connect Wallet")}
                  </Typography>
                </Box>
              )}
            </Box>

            {isConnected && (
              <React.Fragment>
                {myAsset !== null && tab === "v1" && (
                  <Box className={classes.mintTokenBox} mt={3}>
                    <Typography
                      sx={{ fontSize: mdView ? 13 : 16, fontWeight: "500", color: theme.palette.white, mr: 2 }}
                    >
                      {t("Mined V-Token")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: mdView ? 16 : 18,
                        fontWeight: "600",
                        color: theme.palette.stakingAmountColor,
                        mr: 1,
                      }}
                    >
                      {Number(dashboard.groupRewardThis).toFixed(4)}
                    </Typography>
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => {
                        setModalType("rewardPool");
                        setOpen(!open);
                      }}
                    >
                      <Box className={classes.getTokenButton} py={1} px={1.5} sx={{ borderRadius: "6px" }}>
                        <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.stakingAmountColor }}>
                          {t("Get all Tokens")}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                )}
              </React.Fragment>
            )}
          </Box>
        </Container>
      </Box>
      <Footer />
    </Page>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      minHeight: "200vh",
      backgroundColor: theme.palette.defaultColor,
    },
    stakingContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    totalTokenBox: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.black,
      borderRadius: "12px",
    },
    getAllTokenButton: {
      backgroundColor: theme.palette.stakingAmountColor,
      borderRadius: "6px",
      "&:hover": {
        WebKitBoxShadow: `0px 0px 2px 3px ${theme.palette.white}`,
        boxShadow: `0px 0px 2px 3px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    stakingContentsBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "auto",
      minHeight: "60vh",
      justifyContent: "space-between",
      backgroundColor: theme.palette.stakingContentsBox,
      borderRadius: "20px",
    },
    addButtonBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "25px",
      height: 210,
      border: `3px dashed ${theme.palette.stakingBorder}`,
      backgroundColor: theme.palette.stakingNftBox,
      color: theme.palette.stakingBorder,
      "&:hover": {
        border: `3px dashed ${theme.palette.stakingAmountColor}`,
        color: theme.palette.stakingAmountColor,
        cursor: "pointer",
      },
    },
    mintTokenBox: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    getTokenButton: {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.stakingAmountColor}`,
      "&:hover": {
        WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    noMyAssetBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    },
  };
});

export default Staking;
