import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Grid, CardMedia, IconButton } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import CloseButton from "resources/Images/Staking/btn-delete.png";
import { Slider } from "react-stack-slider";

const StakingAssetContents = ({
  tab,
  stakingAsset,
  styleNftId,
  setStyleNftId,
  setModalType,
  open,
  setOpen,
  setStakingMoreAsset,
  setFocusedGroup,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {stakingAsset &&
        stakingAsset.map((item, index) => {
          const [thisKey] = Object.keys(item);
          const openMoreModal = async () => {
            setStakingMoreAsset(item[thisKey]);
            setModalType("more");
            setOpen(!open);
          };
          return (
            <Grid key={"checked" + index} item lg={2.4} md={3} sm={6} xs={6} p={theme.spacing(1)}>
              <Box display="flex" flexDirection="column" position="relative">
                <Box
                  onClick={openMoreModal.bind(item[thisKey])}
                  className={classes.addNft}
                  component="div"
                  onMouseOver={() => {
                    setStyleNftId(thisKey);
                  }}
                  onMouseLeave={() => {
                    setStyleNftId(null);
                  }}
                  sx={{
                    "&:hover": {
                      height: 210,
                      WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                      boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                      cursor: "pointer",
                    },
                  }}
                >
                  <CardMedia
                    component="video"
                    image={item[thisKey][0].image}
                    autoPlay
                    sx={{ width: "100%", height: 210, objectFit: "contain", borderRadius: "25px" }}
                  />
                  <Box className={classes.nameTag} sx={{ opacity: 0.8, backgroundColor: theme.palette.black }}></Box>
                  <Box className={classes.nameTag}>
                    <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white, mr: 0.5 }}>
                      {/* {"RTKV"} */}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white }}>
                      {item[thisKey][0].name}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white }}>
                      {/* {thisKey} */}
                    </Typography>
                  </Box>

                  {styleNftId && styleNftId === thisKey && (
                    <React.Fragment>
                      <Box
                        className={classes.hoverTag}
                        sx={{
                          backgroundColor: theme.palette.black,
                          opacity: 0.6,
                        }}
                      ></Box>
                      <Box
                        className={classes.hoverTag}
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: "500",
                            color: theme.palette.white,
                            borderBottom: `1px solid ${theme.palette.white}`,
                          }}
                        >
                          {t("펼쳐보기")}
                        </Typography>
                      </Box>

                      <Box
                        className={classes.hoverTag}
                        sx={{
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setFocusedGroup(item[thisKey][0].group_id);
                            setModalType("unStaking");
                            setOpen(!open);
                            e.stopPropagation();
                          }}
                          sx={{
                            p: 0,
                            width: 36,
                            height: 36,
                            "&:hover": {
                              WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                              boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
                            },
                          }}
                        >
                          <CardMedia component="img" image={CloseButton} />
                        </IconButton>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>

                <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
                  <Box display="flex">
                    <Typography sx={{ fontSize: 14, fontWeight: "500", color: theme.palette.white, mr: 0.5 }}>
                      {t("Mined V Token")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "600",
                        color: theme.palette.stakingAmountColor,
                      }}
                    >
                      {Number(item[thisKey].reduce((pre, item) => pre + Number(item.group_reward), 0)).toFixed(4)}
                    </Typography>
                  </Box>

                  {tab !== "v1" && (
                    <Box mt={0.5} mb={2}>
                      <Button
                        sx={{ textTransform: "none" }}
                        disabled={item[thisKey][0].harvest_available === "N"}
                        onClick={() => {
                          setFocusedGroup(item[thisKey][0].group_id);
                          setModalType("rewardToken");
                          setOpen(!open);
                        }}
                      >
                        <Box
                          className={classes.getTokenButton}
                          py={1}
                          px={3}
                          sx={{ borderRadius: "19px" }}
                          style={{
                            borderColor: item[thisKey][0].harvest_available === "N" && "#999",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: "500",
                              color:
                                item[thisKey][0].harvest_available === "N" ? "#999" : theme.palette.stakingAmountColor,
                            }}
                          >
                            {t("Get Tokens")}
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    getTokenButton: {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.stakingAmountColor}`,
      "&:hover": {
        WebKitBoxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        boxShadow: `0px 0px 2px 2px ${theme.palette.white}`,
        cursor: "pointer",
      },
    },
    addNft: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.stakingNftBox,
      borderRadius: "25px",
    },

    nameTag: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: 40,
      display: "flex",
      justifyContent: "center",
      borderRadius: "25px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingTop: 10,
      paddingBottom: 10,
    },
    hoverTag: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      borderRadius: "25px",
    },
  };
});

export default StakingAssetContents;
